import React from 'react';

const HowTo = () => {
  return (
    <section className="how-to-work" id="how">
    
		<div className="container">
    <div className="row">
					<div className="col-md-12">
						<div className="new-heading">
							<h1 style={{ textAlign: 'center'}}>How it Works</h1>
						</div>
					</div>
				</div>
        <br />
			<div className="row">
      <div className="col-md-3 col-sm-12 col-xs-12">
					<div className="work-item">
						<div className="work-img">
							<img src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584547875/myhotchop/map_tecxdv.png" alt="" />
						</div>
						<div className="work-text">
							<h4>Select Location</h4>
							<p>Select location where you want us to deliver</p>
						</div>
					</div>
				</div>
				<div className="col-md-3 col-sm-12 col-xs-12">
					<div className="work-item">
						<div className="work-img">
							<img src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584390766/myhotchop/food_hlg9jx.png" alt="" />
						</div>
						<div className="work-text">
							<h4>Choose Food</h4>
							<p>Browse available meals in the location</p>
						</div>
					</div>
				</div>
        <div className="col-md-3 col-sm-12 col-xs-12">
					<div className="work-item">
						<div className="work-img">
							<img src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584391086/myhotchop/pay2_jxvuhs.jpg" alt="" />
						</div>
						<div className="work-text">
							<h4>Check Out (Pay)</h4>
							<p>Checkout your order (Pay on Delivery or Pay with Debit card)</p>
						</div>
					</div>
				</div>
				<div className="col-md-3 col-sm-12 col-xs-12">
					<div className="work-item">
						<div className="work-img">
							<img src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584391539/myhotchop/delivery_co1zk6.png" alt="" />
						</div>
						<div className="work-text">
							<h4>Delivered (doorstep)</h4>
							<p>Your order will be delivered to you in no time</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  );
}

export default HowTo;
