import React, { Fragment, useState } from "react";
import {searchMealsByLocation} from "../../actions/mealsAction";
import { useDispatch, useSelector } from "react-redux";
 

const SearchBox = ({ locationId, token, locationName, history }) => {
  const appState = useSelector(state => state);
  const dispatch = useDispatch();

  const myInput = {
    search: "",
    source: "form"
  };

  const [inputs, setInputs] = useState(myInput);
  const onChange = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(searchMealsByLocation(locationId, inputs.search, token, history, inputs.source));
  };

  return (
    <div className="all-items">
    <br />
    <br />
    <br />
      <div className="search col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <form onSubmit={formSubmitHandler}>
          <input
            className="search-location"
            name="search"
            type="search"
            placeholder="Search for meal..."
            onChange={onChange}
            required
          />
          <div className="icon-btn">
            <div className="s-m-btn">
              <button className="search-meal-btn btn-link">Search</button>
            </div>
          </div>
        </form>
      </div>
      <div className="message">{appState.meals.searchLoadingText}</div>
      <div className="message">{appState.auth.errors.message}</div>
    </div>
  );
};

export default SearchBox;
