import React, { Fragment } from "react";
import Footer from "../Layouts/Footer";
import MessageHeader from '../Layouts/MessageHeader';
import NavBar from '../Layouts/NavBar';
import ContactUs from "../Layouts/ContactUs";

const Contact = () => {
  return (
    <Fragment>
      <MessageHeader />
      <NavBar />
      <ContactUs />
      <Footer />
    </Fragment>
  );
};

export default Contact;
