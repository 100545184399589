import React, {Fragment, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  updateAuth,
  logOutAction,
  logOutAdminAction,
} from '../../actions/userAction';
import {getTotalMealsInCart} from '../../actions/mealsAction';
import jwtDecode from 'jwt-decode';
import {GoogleLogout} from 'react-google-login';
import {useGoogleLogout} from 'react-google-login';

const NavBar = ({history}) => {
  const appState = useSelector((state) => state);
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  const token = localStorage.getItem('token');

  let fullName, role;
  if (token) {
    fullName = jwtDecode(token).firstname + ' ' + jwtDecode(token).lastname;
    role = jwtDecode(token).role;
  }

  useEffect(() => {
    dispatch(updateAuth(isAuthenticated));
    if (token && role === 'user') {
      dispatch(getTotalMealsInCart(token));
    }
  }, [dispatch, isAuthenticated]);
  const logOutHandler = (e) => {
    e.preventDefault();
    dispatch(logOutAction(history));
  };
  const logOutAdminHandler = (e) => {
    e.preventDefault();
    dispatch(logOutAdminAction(history));
  };

  // const {signOut, loaded} = useGoogleLogout({
  //   clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //   jsSrc: 'https://apis.google.com/js/api.js',
  //   onLogoutSuccess: (res) => handleLogoutSuccess(res),
  // });

  // const handleLogoutSuccess = (response) => {
  //   document.cookie.split(';').forEach(function (c) {
  //     document.cookie = c
  //       .replace(/^ +/, '')
  //       .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  //   });

  //   console.log('Logout Success ', response);
  // };

  return (
    <Fragment>
      <br />
      <div className="menu" style={{borderBottom: '1px solid #FFA803'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-12 col-xs-12">
              <div className="menu-left text-center text-md-left">
                <div className="logo-box">
                  <a href="/">
                    <img
                      src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1586616785/myhotchop/logo_ink4d7.png"
                      alt=""
                      height="65px"
                      width="130px"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-10 col-sm-12 col-xs-12">
              <div className="menu-items">
                <nav className="navbar navbar-expand-lg navbar-light bg-light menu-right">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>

                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mr-auto nav-text">
                      {appState.auth.isAuthenticated && role === 'user' ? (
                        <li className="nav-item">
                          <a className="nav-link" href="/store">
                            Home{' '}
                          </a>
                        </li>
                      ) : (
                        ''
                      )}
                      {appState.auth.isAuthenticated && role === 'admin' ? (
                        <Fragment>
                          <li className="nav-item">
                            <a className="nav-link" href="/admin/dashboard">
                              All Eatries/Restaurants
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="/login"
                              onClick={logOutAdminHandler}
                            >
                              Logout ({fullName})
                            </a>
                          </li>
                        </Fragment>
                      ) : (
                        ''
                      )}
                      {!appState.auth.isAuthenticated ? (
                        <Fragment>
                          <li className="nav-item">
                            <a className="nav-link" href="/">
                              Home{' '}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/login">
                              Login
                            </a>
                          </li>

                          <li className="nav-item">
                            <a className="nav-link" href="/create">
                              Create Account
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/order/steps">
                              How To Order
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/faq">
                              FAQ
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/contact">
                              Contact Us
                            </a>
                          </li>
                        </Fragment>
                      ) : (
                        ''
                      )}

                      {appState.auth.isAuthenticated && role === 'user' ? (
                        <Fragment>
                          <li className="nav-item">
                            <a className="nav-link" href="/cart">
                              Cart{' '}
                              <span
                                className="badge badge-secondary"
                                style={{
                                  height: '20px',
                                  width: '20px',
                                  fontSize: '12px',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                <font
                                  style={{position: 'relative', top: '2px'}}
                                >
                                  {appState.meals.cartItems}
                                </font>
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/my/orders">
                              My Orders
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/order/steps">
                              How To Order
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/faq">
                              FAQ
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/contact">
                              Contact Us
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              className="nav-link"
                              style={{
                                textTransform: 'capitalize',
                                cursor: 'pointer',
                              }}
                              onClick={logOutHandler}
                            >
                              {' '}
                              {fullName} (Logout)
                            </a>
                            {/* <GoogleLogout
                              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                              render={(renderProps) => (
                                <button className="nav-link" onClick={signOut}>
                                  {' '}
                                  Logout ({fullName})
                                </button>
                              )}
                              buttonText="Logout"
                              onLogoutSuccess={handleLogoutSuccess}
                            ></GoogleLogout> */}
                          </li>
                        </Fragment>
                      ) : (
                        ''
                      )}
                    </ul>
                  </div>
                </nav>
                <div className="icons-set">
                  <ul className="list-inline">
                    <li className="partner-btn">
                      {/* <a href="/create" className="b-btn btn-link">
												Create Account
											</a> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NavBar;
