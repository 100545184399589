import React, { Fragment } from "react";
import Footer from "../Layouts/Footer";
import MessageHeader from '../Layouts/MessageHeader';
import NavBar from '../Layouts/NavBar';
import Page404 from "../Layouts/Page404";

const Contact = () => {
  return (
    <Fragment>
      <MessageHeader />
      <NavBar />
      <Page404 />
      <Footer />
    </Fragment>
  );
};

export default Contact;
