import * as types from '../constants/actionTypes';
const initialState = {
	allMealsByLocation: [],
	selectedLocation: '',
	cartItems: 0,
	processingToCart: false,
	allMealsInCart: [],
	allOrders: [],
	deliveryCost: 0,
	searchMealsByLocation: [],
	searchLocation: '',
  searchLoadingText: '',
  searchKeyword: '',
  searchSource: ''
};

const mealsReducer = (state = initialState, action) => {
  let { type, payload, location, loaderText, keyword, searchSource } = action;

	switch (type) {
		case types.GET_ALL_MEALS_BY_LOCATION:
			return {
				...state,
				allMealsByLocation: payload,
				selectedLocation: location,
			};
		case types.SEARCH_MEALS_LIST:
			return {
				...state,
				searchMealsByLocation: payload,
        searchLocation: location,
        searchKeyword: keyword,
        searchSource
			};
		case types.START_SEARCH:
			return {
				...state,
				searchLoadingText: loaderText,
			};
		case types.SEARCH_COMPLETE:
			return {
				...state,
				searchLoadingText: '',
			};
		case types.GET_ALL_MEALS_IN_CART:
			return {
				...state,
				allMealsInCart: payload,
			};
		case types.GET_ALL_ORDERS:
			return {
				...state,
				allOrders: payload,
			};
		case types.ADD_ITEM_TO_CART:
			return {
				...state,
				cartItems: state.cartItems + payload,
			};
		case types.GET_ITEM_TOTAL_IN_CART:
			return {
				...state,
				cartItems: payload,
			};
		case types.PROCESSING_CART_LOADING:
			return {
				...state,
				processingToCart: true,
			};
		case types.DELIVERY_COST:
			return {
				...state,
				deliveryCost: payload[0].cost,
			};
		case types.PROCESSING_CART_COMPLETE:
			return {
				...state,
				processingToCart: false,
			};
		default:
			return state;
	}
};

export default mealsReducer;
