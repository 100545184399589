import * as types from "../constants/actionTypes";
const initialState = {
  errors: {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    message: "",
  },
  isAuthenticated: false,
  userInfo: [],
  pwdMessage: "",
  message: ""
};

const authReducer = (state = initialState, action) => {
  const { type, errors, message } = action;

  switch (type) {
    case types.VALIDATION_ERROR:
      return {
        ...state,
        errors,
      };
    case types.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        message,
      };
    case types.IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
      };
    case types.PASSWORD_RESET_SENT:
      return {
        ...state,
        pwdMessage: message,
      };
    case types.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        pwdMessage: message,
      };
    case types.USER_LOGOUT_SUCCESS:
      return state;
    default:
      return state;
  }
};

export default authReducer;
