import React from "react";

const DashboardBar = ({ title }) => {
  return (
    <section className="title-bar">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="left-title-text">
              <h3>{title}</h3>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </section>
  );
};

export default DashboardBar;
