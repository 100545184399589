import React, { Fragment } from "react";
import Footer from "../Layouts/Footer";
import HowTo from "../Layouts/HowTo";
import MessageHeader from '../Layouts/MessageHeader';
import NavBar from '../Layouts/NavBar';

const OrderSteps = () => {
  return (
    <Fragment>
      <MessageHeader />
      <NavBar />
      <HowTo />
      <Footer />
    </Fragment>
  );
};

export default OrderSteps;
