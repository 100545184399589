import React, { Fragment } from "react";
import NavBar from "../Layouts/NavBar";
import FoodList from "../Layouts/FoodList";
import Footer from "../Layouts/Footer";
import MessageHeader from "../Layouts/MessageHeader";
import { useDispatch, useSelector } from 'react-redux';
import Search from "../Layouts/SearchBox"
import {
  logOutAction,
} from "../../actions/userAction";

const ViewMeal = props => {
  const dispatch = useDispatch();
  const locationId = props.match.params.locationId;
  const token = localStorage.getItem("token");
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  if (!token && !isAuthenticated) {
    dispatch(logOutAction());
  }

  return (
    <Fragment>
      <MessageHeader />
      <NavBar />
      <FoodList locationId={locationId} {...props} />
      <Footer />
    </Fragment>
  );
};

export default ViewMeal;
