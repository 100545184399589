import React, { Fragment } from 'react';
import DashboardBar from '../Layouts/DashboardBar';
import MealsList from '../Layouts/MealsList';
import Footer from '../Layouts/Footer';

const AddMeals = ({match}) => {

  return (
    <Fragment>
      <DashboardBar title="Add Meals Page" />
      <MealsList sid={match.params.sid} name={match.params.name} />
      <Footer />
    </Fragment>
  );
}

export default AddMeals;
