import API from '../utilities/api';
import * as types from '../constants/actionTypes';
import clearLocalStorage from '../utilities/clearData';

let errors = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  phone: '',
  message: '',
};

const signUpAction = (userDetails, history) => async (dispatch) => {
  dispatch({
    type: types.IS_LOADING,
  });
  dispatch({
    type: types.VALIDATION_ERROR,
    errors,
  });

  if (userDetails.phone.length < 8) {
    dispatch({
      type: types.VALIDATION_ERROR,
      errors: {
        ...errors,
        phone: 'Phone must be at least 11 numbers!',
      },
    });
    dispatch({type: types.IS_COMPLETE});
  } else {
    try {
      dispatch({
        type: types.VALIDATION_ERROR,
        errors,
      });
      const signUpUrl = 'auth/signup';
      await API.post(signUpUrl, {
        firstname: userDetails.firstname,
        lastname: userDetails.lastname,
        email: userDetails.email,
        phone: userDetails.phone,
        password: userDetails.password,
      })
        .then((res) => {
          if (res.data.statusCode === 201) {
            dispatch({type: types.IS_COMPLETE});
            dispatch({
              type: types.USER_SIGNUP_SUCCESS,
              message: 'Your account has been created successfully!',
            });
          }
        })
        .catch((err) => {
          if (err.response.data.statusCode === 400) {
            dispatch({
              type: types.VALIDATION_ERROR,
              errors: err.response.data.errors,
            });
          }
          if (err.response.data.statusCode === 409) {
            dispatch({
              type: types.VALIDATION_ERROR,
              errors: {
                email: err.response.data.message,
              },
            });
          }
          dispatch({type: types.IS_COMPLETE});
        });
    } catch (err) {
      dispatch({type: types.VALIDATION_ERROR, errors: {}});
    }
  }

  return null;
};

const loginAction = (userInfo, history, role) => async (dispatch) => {
  let loginUrl;
  dispatch({
    type: types.IS_LOADING,
  });
  dispatch({
    type: types.VALIDATION_ERROR,
    errors,
  });
  try {
    if (role === 'admin') {
      loginUrl = 'auth/admin/login';
    } else {
      loginUrl = 'auth/login';
    }
    await API.post(loginUrl, {
      email: userInfo.email,
      password: userInfo.password,
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({type: types.IS_COMPLETE});

          const token = response.data.token;
          localStorage.setItem('token', token);
          localStorage.setItem('isAuthenticated', 'true');
          dispatch({type: types.IS_AUTHENTICATED});
          if (role === 'admin') {
            history.push('/admin/dashboard');
          } else {
            history.push('/store');
          }
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 400) {
          dispatch({
            type: types.VALIDATION_ERROR,
            errors: {
              message: err.response.data.message,
            },
          });
        }

        dispatch({type: types.IS_COMPLETE});
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const googleLoginAction = (tokenId, role, history) => async (dispatch) => {
  let loginUrl;
  dispatch({
    type: types.IS_LOADING,
  });
  dispatch({
    type: types.VALIDATION_ERROR,
    errors,
  });
  try {
    if (role === 'admin') {
      loginUrl = 'auth/admin/login';
    } else {
      loginUrl = 'auth/googlelogin';
    }
    await API.post(loginUrl, {
      tokenId,
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({type: types.IS_COMPLETE});
          const token = response.data.token;
          localStorage.setItem('token', token);
          localStorage.setItem('isAuthenticated', 'true');
          dispatch({type: types.IS_AUTHENTICATED});
          if (role === 'admin') {
            history.push('/admin/dashboard');
          } else {
            history.push('/store');
          }
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 400) {
          dispatch({
            type: types.VALIDATION_ERROR,
            errors: {
              message: `Account doesn't exist on this platform`,
            },
          });
        }

        dispatch({type: types.IS_COMPLETE});
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const facebookLoginAction = (firstName, lastName, email, history) => async (
  dispatch
) => {
  let loginUrl;
  dispatch({
    type: types.IS_LOADING,
  });
  dispatch({
    type: types.VALIDATION_ERROR,
    errors,
  });
  try {
    loginUrl = 'auth/facebooklogin';

    await API.post(loginUrl, {
      firstName,
      lastName,
      email,
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({type: types.IS_COMPLETE});
          const token = response.data.token;
          localStorage.setItem('token', token);
          localStorage.setItem('isAuthenticated', 'true');
          dispatch({type: types.IS_AUTHENTICATED});
          history.push('/store');
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 400) {
          dispatch({
            type: types.VALIDATION_ERROR,
            errors: {
              message: `Account doesn't exist on this platform`,
            },
          });
        }

        dispatch({type: types.IS_COMPLETE});
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const updateUserInfoAction = (userInfo) => async (dispatch) => {
  dispatch({type: types.UPDATE_USER_INFO, payload: userInfo});
  dispatch({
    type: types.UPDATE_USER_INFO,
    payload: userInfo,
  });
};

const updateAuth = (isAuthenticated) => async (dispatch) => {
  if (isAuthenticated === 'true') {
    dispatch({type: types.IS_AUTHENTICATED});
  }
};

const logOutAction = (history) => async (dispatch) => {
  clearLocalStorage();
  dispatch({type: types.USER_LOGOUT_SUCCESS});
  window.location.href = '/login';
};

const logOutAdminAction = (history) => async (dispatch) => {
  clearLocalStorage();
  dispatch({type: types.USER_LOGOUT_SUCCESS});
  window.location.href = '/admin/login';
};

const forgotAction = (userInfo) => async (dispatch) => {
  dispatch({
    type: types.IS_LOADING,
  });
  dispatch({
    type: types.VALIDATION_ERROR,
    errors,
  });
  try {
    const forgotUrl = 'auth/forgot';

    await API.post(forgotUrl, {
      email: userInfo.email,
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({type: types.IS_COMPLETE});
          dispatch({
            type: types.PASSWORD_RESET_SENT,
            message: 'Password reset email sent!',
          });
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 400) {
          dispatch({
            type: types.VALIDATION_ERROR,
            errors: {
              message: err.response.data.message,
            },
          });
        }

        dispatch({type: types.IS_COMPLETE});
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const resetAction = (userInfo, token) => async (dispatch) => {
  dispatch({
    type: types.IS_LOADING,
  });
  dispatch({
    type: types.VALIDATION_ERROR,
    errors,
  });
  try {
    const resetUrl = 'auth/reset';

    await API.put(resetUrl, {
      password: userInfo.password,
      token,
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({type: types.IS_COMPLETE});
          dispatch({
            type: types.PASSWORD_RESET_SUCCESS,
            message: 'Password reset succesful!',
          });
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 400) {
          dispatch({
            type: types.VALIDATION_ERROR,
            errors: {
              message: err.response.data.errors.password,
            },
          });
        }

        if (err.response.data.statusCode === 401) {
          dispatch({
            type: types.VALIDATION_ERROR,
            errors: {
              message:
                'Wrong or Expired Confirmation token! Click on Go to Forgot Password to start a new process',
            },
          });
        }

        dispatch({type: types.IS_COMPLETE});
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

export {
  signUpAction,
  updateUserInfoAction,
  logOutAction,
  loginAction,
  updateAuth,
  logOutAdminAction,
  forgotAction,
  googleLoginAction,
  resetAction,
  facebookLoginAction
};
