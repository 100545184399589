import React from "react";

const ContactUs = () => {
  return (
    <section className="how-to-work" id="how">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="new-heading">
              <h1 style={{textAlign: "center"}}>Contact Us</h1>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-4 col-sm-12 col-xs-12">
            <div className="work-item">
              <div className="work-img">
                <img
                  src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1586621814/myhotchop/email_eezgjc.jpg"
                  alt=""
                />
              </div>
              <div className="work-text">
                <h4>Email</h4>
                <p>info@myhotchop.com</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12">
            <div className="work-item">
              <div className="work-img">
                <img
                  src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1586621813/myhotchop/phone_bcxrpj.jpg"
                  alt=""
                />
              </div>
              <div className="work-text">
                <h4>Phone</h4>
                <p>Sapele: +2348102498344</p>
                <p>Abraka: +2349048533521</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12">
            <div className="work-item">
              <div className="work-img">
                <img
                  src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584547875/myhotchop/map_tecxdv.png"
                  alt=""
                />
              </div>
              <div className="work-text">
                <h4>Address</h4>
                <p>
                  Head Quarter: Shop 2 Old Coca-Cola Deport Olympia, Sapele,
                  Delta, Sapele, Delta, Nigeria
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
