import React, { Fragment } from "react";
import LocationList from "../Layouts/LocationList";
import NavBar from "../Layouts/NavBar";
import Banner from "../Layouts/Banner";
import HowTo from "../Layouts/HowTo";
import Offers from '../Layouts/Offers';
import Footer from "../Layouts/Footer";
import MessageHeader from "../Layouts/MessageHeader";
import jwtDecode from "jwt-decode";


const Main = () => {
  const token = localStorage.getItem("token");
  
	if(token) {
    const role = jwtDecode(token).role;
    if (role === 'admin') {
      window.location.href = '/admin/dashboard'
    }
    else {
      window.location.href = '/store'
    }
	}
	else {
  return (
    <Fragment>
      <MessageHeader />
      <NavBar />
      <Banner createButton={true} />
      <HowTo />
      <Offers />
      {/* <LocationList /> */}
      <Footer />
    </Fragment>
  );
	}
};

export default Main;
