import React from 'react';

const Offers = () => {
	return (
		<section className="offer-banners">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
						<div className="banner">
							<div
								className="ads-banner ads-banner-1"
								
							/>
							<div className="banner-items">
								<div className="bnnr-text">
									<h2>Order Food Online</h2>
									<p>
										Order food from your comfort.
									</p>
								</div>
								<div className="offer-button">
									<a href="/login" className="of-btn btn-link">
										Login
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
						<div className="banner">
							<div
								className="ads-banner ads-banner-2"
							/>
							<div className="banner-items">
								<div className="bnnr-text">
									<h2>
										Create An Account
										
									</h2>
									<p>
										Open an account to today with MyHotChop
									</p>
								</div>
								<div className="offer-button">
									<a href="/create" className="of-btn btn-link">
										Sign up Now
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Offers;
