import React from "react";

const ModalAction = ({ close, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {/* {children} */}
        <div
          className="Qty"
          style={{
            textAlign: "center",
            color: "#000",
            position: "relative",
            top: "-10px"
          }}
        >
          <b
            style={{
                lineHeight: "30px"
            }}
          >Delivery Address or Phone number can't be empty!</b>
          <br />
          <br />
          <br />
          {/* <button
            type=""
            className="confirm-modal btn"   
            onClick={remove}
            id={id}      
          >
            <span id={id}>
              YES{" "}
            </span>
          </button>{" "}
          <button
            type=""
            className="confirm-no-modal btn"
            onClick={closeModal}
          >
            <span>
              No{" "}
            </span>
          </button>{" "} */}
           <button
            type=""
            className="confirm-no-modal btn"
            onClick={close}
          >
            <span>
              Close{" "}
            </span>
          </button>
        </div>
      </section>
    </div>
  );
};

export default ModalAction;
