const orderContact = () => {
  const getLocation = localStorage.getItem("location");
  if (getLocation === "abraka") {
    return "09048533521";
  } else if (getLocation === "sapele") {
    return "08156248343";
  } else {
    return "08156248343";
  }
};

export default orderContact;
