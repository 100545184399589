import React, { Fragment, useState } from "react";
// import MessageHeader from "../Layouts/MessageHeader";
import { useSelector, useDispatch } from "react-redux";
import { loginAction } from "../../actions/userAction";
import jwtDecode from "jwt-decode";

const Login = ({ history }) => {
  const token = localStorage.getItem("token");
  
	if(token) {
    const role = jwtDecode(token).role;
    if (role === 'admin') {
      window.location.href = '/admin/dashboard'
    }
    else {
      window.location.href = '/store'
    }
  }
  const appState = useSelector(state => state);
  const dispatch = useDispatch();
  const myInput = {
    email: "",
    password: ""
  };

  const [inputs, setInputs] = useState(myInput);

  const onChange = e => {
    e.persist();
    setInputs(inputs => ({
      ...inputs,
      [e.target.name]: e.target.value
    }));
  };
  const formSubmitHandler = e => {
    e.preventDefault();
    const userInfo = Object.assign({}, inputs);
    dispatch(loginAction(userInfo, history, 'admin'));
  };
  return (
    <Fragment>
      <section className="login_register">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="login-container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    {appState.loader.appLoader ? (
                      <div className="login-form">
                        <img
                          src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584489170/myhotchop/loader_zowim3.svg"
                          alt="loading"
                        />
                        <br />
                        Logging you in
                        <br />
                        <br />
                      </div>
                    ) : (
                      <form onSubmit={formSubmitHandler}>
                        <div className="login-form">
                          <div className="login-logo"></div>
                          <div className="create-text">
                            <h4>Admin Login</h4>
                          </div>
                          <div>
                            <span className="error-msg">
                              {appState.auth.errors.message}
                            </span>
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              className="video-form"
                              id="emailphonenumber"
                              placeholder="Email"
                              onChange={onChange}
                              required
                              name="email"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              className="video-form"
                              id="yourPassword"
                              placeholder="Password"
                              onChange={onChange}
                              required
                              name="password"
                            />
                          </div>
                          <button type="submit" className="login-btn btn-link">
                            Login Now
                          </button>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Login;
