import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllStores, addStoreAction } from "../../actions/storeAction";
import { ToastContainer } from "react-toastify";
import ProcessFooter from "../Layouts/ProcessFooter";
import "react-toastify/dist/ReactToastify.css";

const StoreList = () => {
  const appState = useSelector((state) => state);
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllStores(token));
  }, [dispatch]);

  const myInput = {
    name: "",
    location: ""
  };

  const [inputs, setInputs] = useState(myInput);

  const onChange = e => {
    e.persist();
    setInputs(inputs => ({
      ...inputs,
      [e.target.name]: e.target.value
    }));
  };

  console.log('state store', inputs)

  const formSubmitHandler = e => {
    e.preventDefault();
    const storeInfo = Object.assign({}, inputs);
    dispatch(addStoreAction(storeInfo, token));
  };


  return (
    <section className="all-partners">
      {appState.loader.appLoader ? (
        <div className="login-form">
          <br />
          <br />
          <br />
          <img
            src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584975303/myhotchop/loader_erskz2.gif"
            alt="loading"
          />
          <br />
          <br />
          <br />
          <font
            style={{
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            Getting all stores...
          </font>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : (
        <Fragment>
          {appState.stores.allStores.length < 1 ? (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <font
                style={{
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                No Stores added yet!
              </font>
              <br />
              <br />

              <br />
              <br />
              <br />
            </div>
          ) : (
            <div className="container">
              <ToastContainer />
              <div className="row">
                <div className="col-lg-8 col-md-8">
                  <div className="my-checkout">
                    {/* <ModalConfirm
                      show={inputs.show}
                      closeModal={closeModal}
                      id={inputs.id}
                      remove={removeFromCart}
                    />
                    <Modal
                      show={inputs.showQty}
                      qty={inputs.cartQty}
                      onPlus={onPlus}
                      onMinus={onMinus}
                      showModal={showQtyModal}
                      closeModal={closeQtyModal}
                      btnMessage={"Update"}
                      id={inputs.id}
                      updateCart={updateCartItem}
                    ></Modal> */}
                    <div className="table-responsive">
                      <table className="table  table-bordered">
                        <thead>
                          <tr>
                            <td className="td-heading">Name</td>
                            <td className="td-heading">Location</td>
                          </tr>
                        </thead>
                        <tbody>
                          {appState.stores.allStores.map((store) => {
                            return (
                              <tr key={store.storeid}>
                                <td>
                                  <div className="checkout-thumb">
                                    <a href="?!">
                                      <img
                                        src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1586105754/myhotchop/online-store_wtzgzx.png"
                                        className="img-responsive"
                                        alt="thumb"
                                        title="thumb"
                                      />
                                    </a>
                                  </div>
                                  <div className="name">
                                    <a href="?!">
                                      <h4>{store.storename}</h4>
                                    </a>
                                    {/* <a href="restaurant_detail.html">
                                      <p>{cart.storename}</p>
                                    </a> */}
                                  </div>
                                </td>
                                <td className="td-content">{store.name.toUpperCase()}</td>
                                <td>
                                  <a href={`/admin/addmeals/`+ store.storename + '/' + store.storeid}>
                                    <button
                                    className="remove-btn"
                                  >
                                    Add Meals
                                  </button>
                                  </a>
                                 
                                  <br />
                                  <br />
                                  {/* <button
                                    className="remove-btn"
                                    // onClick={showModal}
                                    id={store.storeid}
                                  >
                                    Delete Store
                                  </button> */}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tbody>
                          <tr>
                            <td colSpan="4">
                              <h3 className="text-right">
                                {}
                                {/* Total <ins>&#x20A6;{allTotal}</ins> */}
                              </h3>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="note">
                    {/* <p><span>Note :</span>You can only cancel order cancel in 5 minutes after checkout.</p> */}
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-4"
                  style={{
                    position: "rekative",
                    top: "-50px",
                  }}
                >
                  {" "}
                  <div className="your-order">

                    <form onSubmit={formSubmitHandler}>
                      <div className="your-order">
                        <div className="login-logo"></div>
                        <h4>Add A Store</h4>
                        <div>
                          <span className="error-msg">
                            {appState.auth.errors.message}
                          </span>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="video-form"
                            id="storename"
                            placeholder="Store Name"
                            onChange={onChange}
                            required
                            name="name"
                            required
                          />
                        </div>

                        <div className="dropdown bootstrap-select">
                          <select className="select-group"
                          name="location"
                          onChange={onChange}
                          required
                          >
                            <option value="">Select A Location</option>
                            <option value="11000190">Abraka</option>
                            <option value="11000200">Sapele</option>
                            <option value="11009001">Oghara</option>
                          </select>
                        </div>
                        <br />
                        <button type="submit" className="login-btn btn-link">
                          Add Store
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          {appState.meals.processingToCart ? (
            <ProcessFooter message={"Adding Store..."} />
          ) : (
            ""
          )}
        </Fragment>
      )}
    </section>
  );
};

export default StoreList;
