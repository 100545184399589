import React from "react";

const FaqContent = () => {
  return (
    <section className="faq">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="new-heading text-center">
              <h1>Frequently asked questions (FAQ)</h1>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            <div className="faq-items">
              <button className="faq-accordion accordion-bg">
                01.Where is my order ?
              </button>
              <div className="panels">
                <p>
                  After you place your order, we process it and contact the
                  restaurant, which starts preparing it immediately. Our
                  restaurants do everything they can to get your food ready and
                  we deliver as quickly as possible. However, sometimes
                  restaurants receive very large amount of orders, or drivers
                  get stuck in heavy traffic - this unfortunately might cause
                  delays. If the amount of time you’ve waited has exceeded the
                  estimated delivery time, you can contact us and we’ll look
                  into what’s going on.
                </p>
              </div>
              <br />
              <br />
              <button className="faq-accordion accordion-bg">
                02. Ordering
              </button>
              <div className="panels">
                <p>
                  How to order online at Myhotchop.com? It is really easy, as
                  easy as 1, 2, 3: 
				  <br />
				  1. Tell us where you are: Enter/chose your
                  location so that we can show you which restaurants deliver to
                  you. <br />2. Choose what you would like: Pick a restaurant and
                  select items you’d like to order. You can search by restaurant
                  name, cuisine type, dish name or by keyword. <br />3. Checkout:
                  Enter your exact delivery address, payment method and your
                  phone number. Always make sure that you enter the correct
                  phone number to help us contact you regarding your order, if
                  needed. <br />4. Delivery: Now sit back, relax, and we’ll get your
                  food delivered to your doorstep.
                </p>
              </div>
			  <br />
              <br />
              <button className="faq-accordion accordion-bg">
                03. What other way can i place my order?
              </button>
              <div className="panels">
                <p>
                  There are three (3) ways you can place your order <br />1. On our
                  website www.myhotchop.com <br />2. You can call our customer service
                  line +2348156248343 <br />3. You can reach us from any of our social
                  media handle Facebook, Whatsapp, Twitter and Instagram
                </p>
              </div>
			  <br />
              <br />
              <button className="faq-accordion accordion-bg">
                04. I need to cancel or change my order! How can I do this?
              </button>
              <div className="panels">
                <p>
                  Please you&#39;ll be given a 5min grace to cancel or change
                  your order or give us a call as soon as possible, so that we
                  can let the restaurant know before it starts preparing your
                  order. If you would like to cancel your order after it has
                  been confirmed with the restaurant, please call our support
                  team so that we can check if the restaurant has begun
                  preparation. If the restaurant has begun preparing the food,
                  we will be unable to issue a full refund for the order. With
                  regards to any refund of a payment you have made online,
                  please contact Hotchop deliveries on any of our social media
                  platform or call the support team and not the restaurant.
                </p>
              </div>
			  <br />
              <br />
              <button className="faq-accordion accordion-bg">
                05. How long does it take for my order to get delivered?
              </button>
              <div className="panels">
                <p>
                  Delivery time varies from restaurant to restaurant. It also
                  depends on the number of orders that the restaurant has to
                  prepare and on the distance between the restaurant and your
                  delivery address. For Hotchop deliveries its 45mins to 1 hour
                  but you can see the estimated delivery time for each
                  restaurant in your area on our website. After placing an
                  order, a more precise delivery time will be communicated to
                  you by SMS.
                </p>
              </div>
			  <br />
              <br />
              <button className="faq-accordion accordion-bg">
                06. How can I pay for my order?
              </button>
              <div className="panels">
                <p>
                  There are two methods of making payment and they are online
                  payment (Bank Debit card) or Cash on delivery. <br />1. Bank debit
                  card: Hotchop is registered on a secure payment platform which
                  takes care of every card details used for payment hence you
                  can make payment with your bank debit card. <br />2. Cash on Delivery
                  Select ‘Cash on Delivery’ on the checkout page and pay the
                  driver at your doorstep when you receive your order.
                </p>
              </div>
			  <br />
              <br />
              <button className="faq-accordion accordion-bg">
                07. Why is ordering online better than ordering by phone?
              </button>
              <div className="panels">
                <p>
                  Online ordering at Myhotchop.com provides several advantages
                  over telephone ordering. <br />1. Discover: We offer a huge choice
                  of restaurants, and we have their menus online so you don’t
                  have to collect menus for individual restaurants or stick to
                  just one of them. Every day you can discover new restaurants
                  and new tastes. <br />2. Take your time: You have as much time as
                  you need to decide what to eat today, instead of listening to
                  someone read out the entire menu to you and making a decision
                  on the spot. <br />3. Know what you’re getting: We help you make the
                  right choice by providing customer reviews and ratings.
                  <br />4. Convenient payment: If you order Myhotchop.com with your
                  Bank debit card once your card details can be stored if you
                  like and you can make payment another time easily <br />5. You can
                  view details of all your past orders and easily re-order.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqContent;
