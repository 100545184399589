import * as types from "../constants/actionTypes";
const initialState = {
  allStores: [],
  storeMeals: [],
};

const storeReducer = (state = initialState, action) => {
  let { type, payload } = action;

  switch (type) {
    case types.GET_ALL_STORES:
      return {
        ...state,
        allStores: payload,
      };
    case types.GET_STORES_MEALS:
      return {
        ...state,
        storeMeals: payload,
      };
    default:
      return state;
  }
};

export default storeReducer;
