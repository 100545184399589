import React, { Fragment, useState } from "react";
import MessageHeader from "../Layouts/MessageHeader";
import { useSelector, useDispatch } from "react-redux";
import NavBar from "../Layouts/NavBar";
import { forgotAction } from "../../actions/userAction";
import Footer from "../Layouts/Footer";
import jwtDecode from "jwt-decode";


const ForgotPassword = ({ history }) => {
    const token = localStorage.getItem("token");
  
	if(token) {
    const role = jwtDecode(token).role;
    if (role === 'admin') {
      window.location.href = '/admin/dashboard'
    }
    else {
      window.location.href = '/store'
    }
  }
  const appState = useSelector((state) => state);
  const dispatch = useDispatch();
  const myInput = {
    email: "",
  };

  const [inputs, setInputs] = useState(myInput);

  const onChange = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    const userInfo = Object.assign({}, inputs);
    dispatch(forgotAction(userInfo));
  };
  return (
    <Fragment>
      <MessageHeader />
      <NavBar />
      <section className="login_register">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="login-container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    {appState.loader.appLoader ? (
                      <div className="login-form">
                        <img
                          src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584489170/myhotchop/loader_zowim3.svg"
                          alt="loading"
                        />
                        <br />
                        Processing your request...
                        <br />
                        <br />
                      </div>
                    ) : (
                      <form onSubmit={formSubmitHandler}>
                        <div className="login-form">
                          <div className="login-logo"></div>
                          <div className="create-text">
                            <h4>Forgot Password</h4>
                          </div>
                          <div className="error-msg">
                            <span >
                              {appState.auth.errors.message}
                            </span>
                          </div>
                          <div className="success-msg">
                            <span>{appState.auth.pwdMessage}</span>
                          </div>

                          <div className="form-group">
                            <input
                              type="email"
                              className="video-form"
                              id="emailphonenumber"
                              placeholder="Your Email"
                              onChange={onChange}
                              required
                              name="email"
                            />
                          </div>

                          <button type="submit" className="login-btn btn-link">
                            Send
                          </button>
                          <div className="forgot-password">
                            <p>
                              Don’t have an account?{" "}
                              <a href="/create">
                                <span style={{ color: "#ffa803" }}>
                                  - Create An Account
                                </span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default ForgotPassword;
