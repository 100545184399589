import React from "react";

const MessageHeader = () => {
  return (
    <div>
      <header id="header" className="message-header ">
        <div className="topbar">
          <div className="container ">
            <div className="row">
              <div className="col-md-12">
                <div className="topbar-left text-center text-md-left">
                  <div>
                    We are currently available in Delta State, Nigeria only at
                    the moment (Working hours: 9am to 6pm)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default MessageHeader;
