import React, { Fragment } from "react";
import CartBar from "../Layouts/CartBar";
import Footer from "../Layouts/Footer";
import ViewCart from "./ViewCart";

const Cart = () => {
  return (
    <Fragment>
      <CartBar />
      <ViewCart />
      <Footer />
    </Fragment>
  );
};

export default Cart;
