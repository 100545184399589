import React from "react";

const ModalConfirm = ({ closeModal, show, children, qty, remove, id }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  let disabled = false;
  if (qty === 1) {
    disabled = true;
  }
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {children}
        <div
          className="Qty"
          style={{
            textAlign: "center",
            color: "#000",
            position: "relative",
            top: "-10px"
          }}
        >
          <b>Are you sure you want to remove this item?</b>
          <br />
          <br />
          <button
            type=""
            className="confirm-modal btn"   
            onClick={remove}
            id={id}      
          >
            <span id={id}>
              YES{" "}
            </span>
          </button>{" "}
          <button
            type=""
            className="confirm-no-modal btn"
            onClick={closeModal}
          >
            <span>
              No{" "}
            </span>
          </button>{" "}
        </div>
      </section>
    </div>
  );
};

export default ModalConfirm;
