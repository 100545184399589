import API from "../utilities/api";
import * as types from "../constants/actionTypes";
import clearLocalStorage from "../utilities/clearData";
import {toast} from "react-toastify";
import {css} from "glamor";

const notify = (id, message) =>
  toast.success(message, {
    autoClose: 10000,
    toastId: "007",
  });

const errorNotify = (id, message) =>
  toast.error(message, {
    autoClose: 4000,
    toastId: id,
  });

const token = localStorage.getItem("token");

const getAllMealsByLocation = (locationId) => async (dispatch) => {
  dispatch({
    type: types.IS_LOADING,
  });

  try {
    const url = `meals/all/${locationId}`;
    await API.get(url)
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({type: types.IS_COMPLETE});
          dispatch({
            type: types.GET_ALL_MEALS_BY_LOCATION,
            payload: response.data.meals,
            location: response.data.locationName,
          });
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 404) {
          dispatch({
            type: types.VALIDATION_ERROR,
            errors: {
              message: err.response.data.message,
            },
          });
        }

        dispatch({type: types.IS_COMPLETE});
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const addToCart = (id, qty, userToken, locationId) => async (dispatch) => {
  dispatch({type: types.PROCESSING_CART_LOADING});
  const splitId = id.split("-");
  const mealId = splitId[0];
  const storeId = splitId[1];
  try {
    const addCartUrl = "meals/addCart";
    await API.post(
      addCartUrl,
      {
        mealId,
        storeId,
        qty,
        locationId,
      },
      {
        headers: {
          "x-access-token": userToken,
        },
      }
    )
      .then((res) => {
        if (res.data.statusCode === 201) {
          dispatch({type: types.PROCESSING_CART_COMPLETE});
          dispatch({type: types.ADD_ITEM_TO_CART, payload: 1});
          const message = "Added to cart!";
          // notify(1, message);
          toast.success(message, {
            autoClose: 10000,
            toastId: id,
          });
        }
      })
      .catch((err) => {
        dispatch({type: types.PROCESSING_CART_COMPLETE});

        if (
          err.response.data.statusCode === 401 &&
          err.response.data.status === "unauthorized"
        ) {
          clearLocalStorage();
          dispatch({type: types.USER_LOGOUT_SUCCESS});
          window.location.href = "/login";
        }
        if (
          err.response.data.statusCode === 401 &&
          err.response.data.status === "error"
        ) {
          const message =
            "You can't have items in the cart from different locations";
          errorNotify(mealId, message);
        }
        if (err.response.data.statusCode === 404) {
          window.location.href = "/store";
        }
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const deleteFromCart = (cartId, userToken) => async (dispatch) => {
  dispatch({type: types.PROCESSING_CART_LOADING});

  try {
    const deleteCartUrl = "meals/deleteItem";
    await API.post(
      deleteCartUrl,
      {
        cartId: cartId.trim(),
        token: userToken,
      },
      {
        headers: {
          "x-access-token": userToken,
        },
      }
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({type: types.PROCESSING_CART_COMPLETE});
          dispatch({type: types.ADD_ITEM_TO_CART, payload: 1});
          const message = "Menu Item deleted from cart!";
          notify(cartId, message);
          setTimeout(function () {
            window.location.href = "/cart";
          }, 4000);
        }
      })
      .catch((err) => {
        dispatch({type: types.PROCESSING_CART_COMPLETE});

        if (err.response.data.statusCode === 401) {
          clearLocalStorage();
          dispatch({type: types.USER_LOGOUT_SUCCESS});
          window.location.href = "/login";
        }
        // if (err.response.data.statusCode === 404) {
        //   window.location.href = "/cart";
        // }
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const updateCart = (cartId, qty, userToken) => async (dispatch) => {
  dispatch({type: types.PROCESSING_CART_LOADING});

  try {
    const updateCartUrl = "meals/updateItem";
    await API.put(
      updateCartUrl,
      {
        cartId: cartId.trim(),
        token: userToken,
        qty,
      },
      {
        headers: {
          "x-access-token": userToken,
        },
      }
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({type: types.PROCESSING_CART_COMPLETE});
          dispatch({type: types.ADD_ITEM_TO_CART, payload: 1});
          const message = "Menu Item has been updated!";
          notify(cartId, message);
          setTimeout(function () {
            window.location.href = "/cart";
          }, 4000);
        }
      })
      .catch((err) => {
        dispatch({type: types.PROCESSING_CART_COMPLETE});

        if (err.response.data.statusCode === 401) {
          clearLocalStorage();
          dispatch({type: types.USER_LOGOUT_SUCCESS});
          window.location.href = "/login";
        }
        if (err.response.data.statusCode === 404) {
          window.location.href = "/cart";
        }
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const getTotalMealsInCart = (userToken) => async (dispatch) => {
  try {
    const url = `meals/totalMealsInCart`;
    await API.get(url, {
      headers: {
        "x-access-token": userToken,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: types.GET_ITEM_TOTAL_IN_CART,
            payload: parseInt(response.data.total[0].count, 10),
          });
        }
      })
      .catch((err) => {
        dispatch({type: types.IS_COMPLETE});
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const getAllMealsInCart = () => async (dispatch) => {
  dispatch({
    type: types.IS_LOADING,
  });

  try {
    const url = `meals/cartItems`;
    await API.get(url, {
      headers: {
        "x-access-token": token,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: types.GET_ALL_MEALS_IN_CART,
            payload: response.data.cart,
          });
        }
        dispatch({type: types.IS_COMPLETE});
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          clearLocalStorage();
          dispatch({type: types.USER_LOGOUT_SUCCESS});
          window.location.href = "/login";
        }

        dispatch({type: types.IS_COMPLETE});
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const placeUserOrder = (
  allCarts,
  address,
  type,
  phone,
  total,
  deliveryFee,
	userToken,
	location,
) => async (dispatch) => {
  dispatch({type: types.PROCESSING_CART_LOADING});
  const totalLength = allCarts.length;
  let currentLength = 0;
  try {
    const url = `meals/placeOrder`;
    await API.post(
      url,
      {
        allCarts,
        address,
        type,
        phone,
        total,
        deliveryfee: deliveryFee,
        location,
      },
      {
        headers: {
          "x-access-token": userToken,
        },
      }
    )
      .then((response) => {
        if (response.data.statusCode === 201) {
          allCarts.map((cart) => {
            try {
              const url = `meals/addToOrderFromCart`;
              API.put(
                url,
                {
                  cartId: cart.cartid,
                },
                {
                  headers: {
                    "x-access-token": userToken,
                  },
                }
              )
                .then((response) => {
                  if (response.data.statusCode === 200) {
                    currentLength = currentLength + 1;
                    if (currentLength === totalLength) {
                      if (type === "card") {
                        window.location.href = "/order/confirm/card";
                      }
                      if (type === "pod") {
                        window.location.href = "/order/confirm/transfer";
                      }
                      // setTimeout(function () {
                      //   window.location.href = "/order/confirm";
                      // }, 1000);
                      dispatch({type: types.PROCESSING_CART_COMPLETE});
                    }
                  }
                })
                .catch((err) => {
                  if (err.response.data.statusCode === 401) {
                    clearLocalStorage();
                    dispatch({type: types.USER_LOGOUT_SUCCESS});
                    window.location.href = "/login";
                  }

                  dispatch({type: types.IS_COMPLETE});
                });
            } catch (err) {
              dispatch({type: types.VALIDATION_ERROR, errors: {}});
            }
          });
        }
        dispatch({type: types.PROCESSING_CART_COMPLETE});
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          clearLocalStorage();
          dispatch({type: types.USER_LOGOUT_SUCCESS});
          window.location.href = "/login";
        }

        dispatch({type: types.IS_COMPLETE});
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const getAllOrders = () => async (dispatch) => {
  dispatch({
    type: types.IS_LOADING,
  });

  try {
    const url = `meals/allorders`;
    await API.get(url, {
      headers: {
        "x-access-token": token,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: types.GET_ALL_ORDERS,
            payload: response.data.orders,
          });
        }
        dispatch({type: types.IS_COMPLETE});
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          clearLocalStorage();
          dispatch({type: types.USER_LOGOUT_SUCCESS});
          window.location.href = "/login";
        }

        dispatch({type: types.IS_COMPLETE});
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const getDeliveryCost = (token) => async (dispatch) => {
  try {
    const url = `meals/deliveryCost`;
    await API.get(url, {
      headers: {
        "x-access-token": token,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: types.DELIVERY_COST,
            payload: response.data.cost,
          });
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          clearLocalStorage();
          dispatch({type: types.USER_LOGOUT_SUCCESS});
          window.location.href = "/login";
        }

        dispatch({type: types.IS_COMPLETE});
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

const searchMealsByLocation = (
  locationId,
  keyword,
  userToken,
  history,
  source
) => async (dispatch) => {
  dispatch({
    type: types.START_SEARCH,
    loaderText: `Searching for ${keyword}...`,
  });

  try {
    const url = `meals/search/${locationId}/?keyword=${keyword}`;
    await API.get(url, {
      headers: {
        "x-access-token": userToken,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({type: types.SEARCH_COMPLETE});
          dispatch({
            type: types.SEARCH_MEALS_LIST,
            payload: response.data.meals,
            locationId,
            location: response.data.locationName,
            keyword,
            searchSource: source,
          });

          history.push(`/search/${locationId}/?keyword=${keyword}`);
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 400) {
          dispatch({type: types.SEARCH_COMPLETE});
          dispatch({
            type: types.VALIDATION_ERROR,
            errors: {
              message: err.response.data.errors.keyword[0],
            },
          });
        }
      });
  } catch (err) {
    dispatch({type: types.VALIDATION_ERROR, errors: {}});
  }
};

export {
  getAllMealsByLocation,
  addToCart,
  getTotalMealsInCart,
  getAllMealsInCart,
  deleteFromCart,
  updateCart,
  placeUserOrder,
  getAllOrders,
  getDeliveryCost,
  searchMealsByLocation,
};
