import React from 'react';

const Banner = ({ createButton }) => {
	return (
		<section className="block-preview">
			<div className="cover-banner banner overlay" />
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12">
						<div className="left-text-a">
							<h1 className="title">Welcome to MyHot Chop</h1>
							<h6 className="exeption">Choose and Order meals at your comfort</h6>
							{/* <p>1. You Choose, &nbsp; 2. You Order, &nbsp; 3. We Deliver </p> */}
							{createButton ? (
								<a className="bnr-btn btn-link" href="/create">
									Create An Account
								</a>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Banner;
