import React from "react";

const Page404 = () => {
  return (
    <section className="how-to-work" id="how">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="new-heading">
              <h3 style={{ textAlign: "center" }}>Oops! Page does not exist!</h3>
            </div>
          </div>
        </div>
        <br />
      </div>
    </section>
  );
};

export default Page404;
