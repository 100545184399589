import React, { Fragment } from "react";
import DashboardBar from '../Layouts/DashboardBar';
import OrderRecieptTransfer from '../Layouts/OrderRecieptTransfer';

const OrderConfirm = () => {
  return (
    <Fragment>
      <DashboardBar title="Order Confirmation" />
      <OrderRecieptTransfer />
    </Fragment>
  );
};

export default OrderConfirm;
