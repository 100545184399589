import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="privacy-cards">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="privacy-terms">
                <ul>
                  <li>
					<a
					 target="_blank"
					 href="https://drive.google.com/file/d/1KL6zELNrnJpyGv37t8ZFBZvHMXnZTZMA/view?usp=sharing">
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="cards">
                <img src="images/cards.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="copyright-text">
                <i className="far fa-copyright"></i>Copyright 2020 &nbsp;
                <a href="/">HOTCHOP DELIVERIES</a>. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
