import React, { Fragment } from 'react';
import DashboardBar from '../Layouts/DashboardBar';
import StoreList from '../Layouts/StoreList';
import Footer from '../Layouts/Footer';

const Dashboard = () => {
  return (
    <Fragment>
      <DashboardBar title="Admin Dashboard" />
      <StoreList />
      <Footer />
    </Fragment>
  );
}

export default Dashboard;
