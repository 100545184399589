import React from "react";

const ModalForm = ({
  closeModalForm,
  showForm,
  onChange,
  name,
  price,
  url,
  formSubmitHandler
}) => {
  const showHideClassName = showForm
    ? "modal display-block"
    : "modal display-none";
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <form
          style={{
            position: "relative",
            top: "-30px",
          }}
          onSubmit={formSubmitHandler}
        >
          <div className="login-logo"></div>
          <h4
            style={{
              textAlign: "center",
              padding: "20px",
            }}
          >
            Update the Meal
          </h4>
          <div className="form-group">
            <input
              type="text"
              className="video-form"
              id="name"
              placeholder="Name"
              defaultValue={name}
              onChange={onChange}
              name="modalName"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              className="video-form"
              id="price"
              placeholder="Price"
              defaultValue={price}
              onChange={onChange}
              name="modalPrice"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="video-form"
              id="url"
              placeholder="Image URL"
              defaultValue={url}
              onChange={onChange}
              name="modalUrl"
              required
            />
          </div>
          <button type="submit" className="login-btn btn-link">
            Update Meal
          </button>
          <br />
          <button
            type=""
            className="login-btn btn-link"
            style={{
              backgroundColor: "maroon",
              borderColor: "maroon",
            }}
            onClick={closeModalForm}
          >
            Close
          </button>
        </form>
      </section>
    </div>
  );
};

export default ModalForm;
