import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrders,
  deleteFromCart,
  updateCart,
  placeUserOrder,
} from "../../actions/mealsAction";
import ModalConfirm from "../Layouts/ModalConfirm";
import Modal from "../Layouts/Modal";
import { ToastContainer, toast } from "react-toastify";
import ProcessFooter from "../Layouts/ProcessFooter";
import "react-toastify/dist/ReactToastify.css";
import Rave, { VerifyTransaction } from "react-flutterwave-rave";
import jwtDecode from "jwt-decode";

const OrderList = () => {
  const appState = useSelector((state) => state);
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  return (
    <section className="all-partners">
      {appState.loader.appLoader ? (
        <div className="login-form">
          <br />
          <br />
          <br />
          <img
            src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584975303/myhotchop/loader_erskz2.gif"
            alt="loading"
          />
          <br />
          <br />
          <br />
          <font
            style={{
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            Loading orders...
          </font>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : (
        <Fragment>
          {appState.meals.allOrders.length < 1 ? (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <font
                style={{
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                You have no order yet!
              </font>
              <br />
              <br />
              <a href="/store">
                <font
                  style={{
                    color: "#FFA803",
                  }}
                >
                  Go Home
                </font>
              </a>
            </div>
          ) : (
            <div className="container">
              <ToastContainer />
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="my-checkout">
                    {appState.meals.allOrders.map((order) => {
                      return (
                        <Fragment>
                          <br />
                          <br />
                          <hr />
                          <br />
                          <div className="order-header">
                            <strong>Order ID: {order.orderid}</strong>
                          </div>
                          <br />
                          <div className="order-header">
                            <strong>Shipping Address: {order.address}</strong>
                          </div>
                          <br />
                          <div className="order-header">
                            <strong>
                              Payment Type:
                              {order.type === "pod"
                                ? " Payment by Transfer"
                                : " Debit Card"}
                            </strong>
                          </div>
                          <br />
                          <div className="order-header">
                            <strong>Order Date: {order.time}</strong>
                          </div>
                          <br />
                          <div className="table-responsive">
                            <table className="table  table-bordered">
                              <thead>
                                <tr>
                                  <td className="td-heading">Meal</td>
                                  <td className="td-heading">Qty</td>
                                  <td className="td-heading">
                                    Price (&#x20A6;)
                                  </td>
                                  {/* <td className="td-heading">Action</td> */}
                                </tr>
                              </thead>
                              <tbody>
                                {JSON.parse(order.cartinfo).map((cart) => {
                                  return (
                                    <tr key={cart.cartid}>
                                      <td>
                                        <div className="checkout-thumb">
                                          <a href="?!">
                                            <img
                                              src={cart.pic}
                                              className="img-responsive"
                                              alt="thumb"
                                              title="thumb"
                                            />
                                          </a>
                                        </div>
                                        <div className="name">
                                          <a href="?!">
                                            <h4>{cart.name}</h4>
                                          </a>
                                          <a href="restaurant_detail.html">
                                            <p>{cart.storename}</p>
                                          </a>
                                        </div>
                                      </td>
                                      <td className="td-content">{cart.qty}</td>
                                      <td className="td-content">
                                        {" "}
                                        {cart.price}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan="4">
                                    <h5 className="text-right"
                                      style={{
                                        fontSize: '16px'
                                      }}
                                    >
                                      {}
                                      Total + Delivery Fee (&#x20A6;
                                      {order.deliveryfee})
                                      <ins>
                                        &#x20A6;
                                        {parseInt(order.total, 10) +
                                          parseInt(order.deliveryfee, 10)}
                                      </ins>
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>

                  <div className="note">
                    {/* <p><span>Note :</span>You can only cancel order cancel in 5 minutes after checkout.</p> */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {appState.meals.processingToCart ? (
            <ProcessFooter message={"Processing"} />
          ) : (
            ""
          )}
        </Fragment>
      )}
    </section>
  );
};

export default OrderList;
