import React, { Fragment } from "react";
import DashboardBar from '../Layouts/DashboardBar';
import OrderReciept from '../Layouts/OrderReciept';

const OrderConfirm = () => {
  return (
    <Fragment>
      <DashboardBar title="Order Confirmation" />
      <OrderReciept />
    </Fragment>
  );
};

export default OrderConfirm;
