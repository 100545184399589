import API from "../utilities/api";
import * as types from "../constants/actionTypes";
import clearLocalStorage from "../utilities/clearData";
import { toast } from "react-toastify";

const notify = (id, message) =>
  toast.success(message, {
    autoClose: 3000,
    toastId: id
  });

  const errorNotify = (id, message) =>
  toast.error(message, {
    autoClose: 4000,
    toastId: id
  });

const token = localStorage.getItem("token");

const getAllStores = (userToken) => async dispatch => {
  dispatch({
    type: types.IS_LOADING
  });

  try {
    const url = `store/all`;
    await API.get(
        url
        ,
        {
            headers: {
              "x-access-token": userToken
            }
          }
        )
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({ type: types.IS_COMPLETE });
          dispatch({
            type: types.GET_ALL_STORES,
            payload: response.data.stores,
          });
        }
      })
      .catch(err => {
        if (err.response.data.statusCode === 401) {
        }
            window.location.href = "/login";

        dispatch({ type: types.IS_COMPLETE });
      });
  } catch (err) {
    dispatch({ type: types.VALIDATION_ERROR, errors: {} });
  }
};

const addStoreAction = (storeInfo, userToken) => async (dispatch) => {
    dispatch({ type: types.PROCESSING_CART_LOADING });

	dispatch({
		type: types.VALIDATION_ERROR,
		errors: ''
	});
	try {
		const apiUrl = 'store/add'
		await API.post(apiUrl, {
			name: storeInfo.name,
			locationId: storeInfo.location
        },
        {
            headers: {
              "x-access-token": userToken
            }
          }
        
        )
			.then((response) => {
				if (response.data.statusCode === 201) {
					// dispatch({ type: types.IS_COMPLETE });
                    dispatch({ type: types.PROCESSING_CART_COMPLETE });
                    // dispatch({ type: types.ADD_ITEM_TO_CART, payload: 1 });
                    const message = "Store Added!";
                    notify('store', message);
                    setTimeout(function(){
                        window.location.href = "/admin/dashboard";
                     }, 4000);
                     
				}
			})
			.catch((err) => {
                dispatch({ type: types.PROCESSING_CART_COMPLETE });
				if (err.response.data.statusCode === 400) {
					dispatch({
						type: types.VALIDATION_ERROR,
						errors: {
							message: err.response.data.message
						}
					});
                }

                if (err.response.data.statusCode === 401) {
                //   window.location.href = "/admin/login";
                }

				dispatch({ type: types.IS_COMPLETE });
			});
	} catch (err) {
		dispatch({ type: types.VALIDATION_ERROR, errors: {} });
	}
};

const getStoreMeals = (userToken, sid) => async dispatch => {
  dispatch({
    type: types.IS_LOADING
  });

  try {
    const url = `store/allmeals/`+ sid;
    await API.get(
        url
        ,
        {
            headers: {
              "x-access-token": userToken
            }
          }
        )
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({ type: types.IS_COMPLETE });
          dispatch({
            type: types.GET_STORES_MEALS,
            payload: response.data.meals,
          });
        }
      })
      .catch(err => {
        if (err.response.data.statusCode === 401) {
            window.location.href = "/login";
        }
        if (err.response.data.statusCode === 404) {
          window.location.href = "/admin/dashboard";
      }

        dispatch({ type: types.IS_COMPLETE });
      });
  } catch (err) {
    dispatch({ type: types.VALIDATION_ERROR, errors: {} });
  }
};

const addMealAction = (mealInfo, storeName, sid, userToken) => async (dispatch) => {
  dispatch({ type: types.PROCESSING_CART_LOADING });
dispatch({
  type: types.VALIDATION_ERROR,
  errors: ''
});
try {
  const apiUrl = 'store/addmeal'
  await API.post(apiUrl, {
    name: mealInfo.name,
    storeId: sid,
    price: mealInfo.price,
    pic1: mealInfo.url
      },
      {
          headers: {
            "x-access-token": userToken
          }
        }
      
      )
    .then((response) => {
      if (response.data.statusCode === 201) {
                  dispatch({ type: types.PROCESSING_CART_COMPLETE });
                  const message = "Meal Added!";
                  notify('meal', message);
                  setTimeout(function(){
                      window.location.href = "/admin/addmeals/" + storeName + '/' + sid;
                   }, 4000);
                   
      }
    })
    .catch((err) => {
              dispatch({ type: types.PROCESSING_CART_COMPLETE });
      if (err.response.data.statusCode === 400) {
        dispatch({
          type: types.VALIDATION_ERROR,
          errors: {
            message: err.response.data.message
          }
        });
              }

              if (err.response.data.statusCode === 401) {
                window.location.href = "/admin/login";
              }

      dispatch({ type: types.IS_COMPLETE });
    });
} catch (err) {
  dispatch({ type: types.VALIDATION_ERROR, errors: {} });
}
};


const deleteMealAction = (mealId, sid, name, userToken) => async dispatch => {
  dispatch({ type: types.PROCESSING_CART_LOADING });

  try {
    const deleteUrl = "store/deleteMeal";
    await API.post(
      deleteUrl,
      {
        mealId: mealId.trim(),
        token: userToken
      },
      {
        headers: {
          "x-access-token": userToken
        }
      }
    )
      .then(res => {
        if (res.data.statusCode === 200) {
          dispatch({ type: types.PROCESSING_CART_COMPLETE });
          const message = "Meal deleted from store!";
          notify(mealId, message);
          setTimeout(function(){
            window.location.href = "/admin/addmeals/" + name + '/' + sid;
          }, 4000);
        }
      })
      .catch(err => {
        dispatch({ type: types.PROCESSING_CART_COMPLETE });

        if (err.response.data.statusCode === 401) {
          window.location.href = "/admin/login";
        }
        if (err.response.data.statusCode === 404) {
          window.location.href = "/admin/dashboard";
        }
      });
  } catch (err) {
    dispatch({ type: types.VALIDATION_ERROR, errors: {} });
  }
};

const updateMealAction = (mealInfo, storeName, sid, mealId, userToken) => async (dispatch) => {
  dispatch({ type: types.PROCESSING_CART_LOADING });
dispatch({
  type: types.VALIDATION_ERROR,
  errors: ''
});
try {
  const apiUrl = 'store/updatemeal'
  await API.put(apiUrl, {
    name: mealInfo.modalName,
    storeId: sid,
    price: mealInfo.modalPrice,
    pic1: mealInfo.modalUrl,
    mealId

      },
      {
          headers: {
            "x-access-token": userToken
          }
        }
      
      )
    .then((response) => {
      if (response.data.statusCode === 200) {
                  dispatch({ type: types.PROCESSING_CART_COMPLETE });
                  const message = "Meal Updated!";
                  notify('meal', message);
                  setTimeout(function(){
                      window.location.href = "/admin/addmeals/" + storeName + '/' + sid;
                   }, 4000);
                   
      }
    })
    .catch((err) => {
              dispatch({ type: types.PROCESSING_CART_COMPLETE });
      if (err.response.data.statusCode === 400) {
        dispatch({
          type: types.VALIDATION_ERROR,
          errors: {
            message: err.response.data.message
          }
        });
              }

              if (err.response.data.statusCode === 401) {
                window.location.href = "/admin/login";
              }

      dispatch({ type: types.IS_COMPLETE });
    });
} catch (err) {
  dispatch({ type: types.VALIDATION_ERROR, errors: {} });
}
};

export {
  getAllStores,
  addStoreAction,
  getStoreMeals,
  addMealAction,
  deleteMealAction,
  updateMealAction
};
