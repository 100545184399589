import React, { Fragment } from "react";
import Footer from "../Layouts/Footer";
import HowTo from "../Layouts/HowTo";
import DashboardBar from '../Layouts/DashboardBar';
import OrderList from '../Layouts/OrderList';
import NavBar from '../Layouts/NavBar';

const MyOrders = () => {
  return (
    <Fragment>
      <DashboardBar title="My Orders" />
      <OrderList />
    </Fragment>
  );
};

export default MyOrders;
