import React, {Fragment} from 'react';
import NavBar from '../Layouts/NavBar';
import SearchList from '../Layouts/SearchList';
import Footer from '../Layouts/Footer';
import MessageHeader from '../Layouts/MessageHeader';
import {useDispatch} from 'react-redux';
import {logOutAction} from '../../actions/userAction';

const SearchMeal = props => {
  const locationId = props.match.params.locationId;
  const dispatch = useDispatch ();
  const token = localStorage.getItem ('token');
  const isAuthenticated = localStorage.getItem ('isAuthenticated');

  if (!token && !isAuthenticated) {
    dispatch (logOutAction ());
  }

  return (
    <Fragment>
      <MessageHeader />
      <NavBar />
      <SearchList locationId={locationId} history={props.history} {...props} />
      <Footer />
    </Fragment>
  );
};

export default SearchMeal;
