import React, { Component, Fragment } from "react";
import jwtDecode from "jwt-decode";
import NavBar from "../components/Layouts/NavBar";
import MessageHeader from "../components/Layouts/MessageHeader";

const checkAuthentication = (WrappedComponent) => {
  class Authentication extends Component {
    componentDidMount() {
      const token = localStorage.getItem("token") || "";
      const { history } = this.props;
      const isAuthenticated = localStorage.getItem("isAuthenticated");

      if (!token || isAuthenticated !== "true") {
        history.push("/login");
      } else {
        const decode = jwtDecode(token);

        if (!decode) {
          history.push("/login");
        }
        const checkTokenExpiryDate = decode.exp - Math.floor(Date.now() / 1000);
        if (checkTokenExpiryDate <= 0) {
          history.push("/login");
        }
      }
    }

    render() {
      return (
        <Fragment>
          <MessageHeader />
          <NavBar
          // fullName={fullName}
          // role={myRole}
          // userId={userId}
          // {...this.props}
          // isAuthenticated={isAuthenticated}
          />
          <WrappedComponent {...this.props} />
        </Fragment>
      );
    }
  }

  return Authentication;
};

export default checkAuthentication;
