import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMealsByLocation, addToCart } from "../../actions/mealsAction";
import Modal from "../Layouts/Modal";
import { ToastContainer, toast } from "react-toastify";
import ProcessFooter from '../Layouts/ProcessFooter';
import SearchBox from "../Layouts/SearchBox"
import "react-toastify/dist/ReactToastify.css";

const FoodList = props => {
  const token = localStorage.getItem("token");
  const appState = useSelector(state => state);
  const myInput = {
    qty: 1,
    show: false,
    id: ""
  };
  const [inputs, setInputs] = useState(myInput);

  const onPlus = e => {
    e.persist();
    setInputs(inputs => ({
      ...inputs,
      qty: inputs.qty + 1
    }));
  };

  const onMinus = e => {
    e.persist();
    setInputs(inputs => ({
      ...inputs,
      qty: inputs.qty - 1
    }));
  };

  const showModal = e => {
    e.persist();
    setInputs(inputs => ({
      ...inputs,
      show: true,
      id: e.target.id
    }));
  };

  const closeModal = e => {
    e.persist();
    setInputs(inputs => ({
      ...inputs,
      show: false,
      id: '',
      qty: 1
    }));
  };


  const [allMeals, updateMeals] = useState(myInput);


  const locationId = props.locationId;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllMealsByLocation(locationId));
  }, [dispatch, appState.auth.userInfo]);


  const updateCart = e => {
    e.persist();
    dispatch(addToCart(inputs.id, inputs.qty, token, locationId));
    closeModal(e);
  };

  return (
    <div>
      {appState.loader.appLoader ? (
        <div className="login-form">
           <br />
                        <br />
                        <br />
                        <img
                          src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584975303/myhotchop/loader_erskz2.gif"
                          alt="loading"
                        />
                        <br />
                        <br />
                        <br />
                        <font style={{
                            color: '#000000',
                            fontWeight: 'bold'
                        }}>Loading meals...</font>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
          <br />
        </div>
      ) : (
        <Fragment>
          <div className="col-md-12">
            <div className="new-heading meal-heading">
              <h1>
                {" "}
                Discover Meals in "
                {appState.meals.selectedLocation.toUpperCase()}" & Order Now{" "}
              </h1>
            </div>
          </div>
          <SearchBox
            token={token}
            locationId={locationId}
            locationName={appState.meals.selectedLocation.toUpperCase()}
            history={props.history}
            
          />
          
          <Modal
            show={inputs.show}
            qty={inputs.qty}
            onPlus={onPlus}
            onMinus={onMinus}
            showModal={showModal}
            closeModal={closeModal}
            updateCart={updateCart}
            btnMessage={"Add"}
          ></Modal>

          <section className="order-food-online">
            <div className="container">
              <div className="row">
                {appState.meals.allMealsByLocation.map((meal, index) => {

                  let tid = meal.mealid.substring(1, 3);
                  return (
                    <div
                      className="col-lg-3 col-md-6 col-sm-12 col-xs-12"
                      key={index}
                    >
                      <div className="all-meal">
                        <div className="top">
                          {/* <a href="?#">
                            <div className="bg-gradient" />
                          </a> */}
                          <div className="top-img">
                            <img
                              src={meal.pic1}
                              alt=""
                              width="100%"
                              height="150px"
                            />
                          </div>
                          <div className="logo-img">
                            <img
                              src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584393735/myhotchop/meal_tump7r.jpg"
                              alt=""
                            />
                          </div>
                          <div className="top-text">
                            <div className="heading">
                              <h4>
                                {meal.name}
                              </h4>
                            </div>
                            <div className="sub-heading">
                              {/* <h5>
									  <a href="restaurant_detail.html">{meal.name}</a>
									</h5> */}
                              <p>&#x20A6; {meal.price}</p>
                            </div>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="bottom-text">
                            <div className="delivery">
                              <i className="fas fa-store" />
                              Restaurant : {meal.storename}
                            </div>
                            <div className="time">
                              <i className="far fa-clock" />
                              Delivery Time : 30 Min
                            </div>
                          </div>
                          <br />
                          <ToastContainer />
                          <button
                            type=""
                            className="add-cart btn"
                            onClick={showModal}
                            id={meal.mealid + "-" + meal.storeid}
                          >
                            <span
                              onClick={showModal}
                              id={meal.mealid + "-" + meal.storeid}
                            >
                              <i className="fas fa-plus-square" /> Add to Cart{" "}
                            </span>
                          </button>{" "}
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <br />
            <br />
            <br />
          </section>
          {appState.meals.processingToCart ? (
            <ProcessFooter message={"Adding to cart..."}/>
          ) : (
            ""
          )}
        </Fragment>
      )}
    </div>
  );
};

export default FoodList;
