import { combineReducers } from 'redux';
import loaderReducer from './loaderReducer';
import authReducer from './authReducer';
import mealsReducer from './mealsReducer';
import storesReducer from './storesReducer';

const rootReducer = combineReducers({
	loader: loaderReducer,
	auth: authReducer,
	meals: mealsReducer,
	stores: storesReducer,
});

export default rootReducer;
