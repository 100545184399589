import React, { Fragment } from 'react';
// import NavBar from '../Layouts/NavBar';
import LocationList from '../Layouts/LocationList';
import Banner from '../Layouts/Banner';
import Footer from '../Layouts/Footer';

const Store = () => {
  return (
    <Fragment>
			<Banner createButton={false} />
      <LocationList />
      <Footer />
    </Fragment>
  );
}

export default Store;
