import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMealAction,
  getStoreMeals,
  deleteMealAction,
  updateMealAction
} from "../../actions/storeAction";
import ModalConfirm from "../Layouts/ModalConfirm";
import ModalForm from "../Layouts/ModalForm";
import { ToastContainer } from "react-toastify";
import ProcessFooter from "../Layouts/ProcessFooter";
import "react-toastify/dist/ReactToastify.css";

const MealsList = ({ sid, name }) => {
  const appState = useSelector((state) => state);
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStoreMeals(token, sid));
  }, [dispatch]);

  const myInput = {
    name: "",
    url: "",
    price: "",
    show: false,
    id: "",
    showForm: false,
    modalName: '',
    modalPrice: '',
    modalUrl: ''
  };

  const [inputs, setInputs] = useState(myInput);

  const onChange = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    const mealInfo = Object.assign({}, inputs);
    dispatch(addMealAction(mealInfo, name, sid, token));
  };

  
  const updateMealSubmitHandler = (e) => {
    e.preventDefault();
    const mealInfo = Object.assign({}, inputs);
    dispatch(updateMealAction(mealInfo, name, sid, inputs.id, token));
  };

  
  const showModal = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      show: true,
      id: e.target.id,
    }));
  };

  const showModalForm = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      showForm: true,
      id: e.target.id,
    }));
    filterMeal(e, e.target.id)
  };

  const closeModal = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      show: false,
      id: "",
    }));
  };

  const closeModalForm = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      showForm: false,
      id: "",
      modalName: '',
      modalPrice: '',
      modalUrl: ''
    }));
  };

  const removeMeal = (e) => {
    e.persist();
    const mealId = e.target.id;
    console.log('mid', mealId)
    dispatch(deleteMealAction(mealId, sid, name, token));
    closeModal(e);
  };

  const filterMeal = (e, id) => {
    e.persist()
    let mealInfo = appState.stores.storeMeals.filter((meal) => {
      return meal.mealid ===  id
    })

    setInputs((inputs) => ({
      ...inputs,
      modalName: mealInfo[0].name,
      modalPrice: mealInfo[0].price,
      modalUrl: mealInfo[0].pic1
    }));
  }

  return (
    <section className="all-partners">
      {appState.loader.appLoader ? (
        <div className="login-form">
          <br />
          <br />
          <br />
          <img
            src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584975303/myhotchop/loader_erskz2.gif"
            alt="loading"
          />
          <br />
          <br />
          <br />
          <font
            style={{
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            Getting store meals...
          </font>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : (
        <Fragment>
          <div className="container">
            <ToastContainer />
            <div className="row">
              {appState.stores.storeMeals.length < 1 ? (
                <div
                  style={{
                    textAlign: "center",
                  }}
                  className="col-lg-8 col-md-8"
                >
                  <font
                    style={{
                      color: "#000000",
                      fontWeight: "bold",
                    }}
                  >
                    No meals added yet for {name}!
                  </font>
                  <br />
                  <br />

                  <br />
                  <br />
                  <br />
                </div>
              ) : (
                <div className="col-lg-8 col-md-8">
                  <h4>
                    {" "}
                    <strong>List of meals from {name} </strong>
                  </h4>
                  <br />
                  <div className="my-checkout">
                    <ModalConfirm
                      show={inputs.show}
                      closeModal={closeModal}
                      id={inputs.id}
                      remove={removeMeal}
                    />
                    <ModalForm
                      showForm={inputs.showForm}
                      id={inputs.id}
                      closeModalForm={closeModalForm}
                      name={inputs.modalName}
                      price={inputs.modalPrice}
                      url={inputs.modalUrl}
                      formSubmitHandler={updateMealSubmitHandler}
                      onChange={onChange}
                    /> 
                    {/*<Modal
                      show={inputs.showQty}
                      qty={inputs.cartQty}
                      onPlus={onPlus}
                      onMinus={onMinus}
                      showModal={showQtyModal}
                      closeModal={closeQtyModal}
                      btnMessage={"Update"}
                      id={inputs.id}
                      updateCart={updateCartItem}
                    ></Modal> */}
                    <div className="table-responsive">
                      <table className="table  table-bordered">
                        <thead>
                          <tr>
                            <td className="td-heading">Name</td>
                            <td className="td-heading">Price</td>
                            <td className="td-heading">Actions</td>
                          </tr>
                        </thead>
                        <tbody>
                          {appState.stores.storeMeals.map((meal) => {
                            return (
                              <tr key={meal.mealid}>
                                <td>
                                  <div className="checkout-thumb">
                                    <a href="?!">
                                      <img
                                        src={meal.pic1}
                                        className="img-responsive"
                                        alt="thumb"
                                        title="thumb"
                                      />
                                    </a>
                                  </div>
                                  <div className="name">
                                    <a href="?!">
                                      <h4>{meal.name}</h4>
                                    </a>
                                    {/* <a href="restaurant_detail.html">
                                      <p>{cart.storename}</p>
                                    </a> */}
                                  </div>
                                </td>
                                <td className="td-content">{meal.price}</td>
                                <td>
                                  <button
                                    className="remove-btn"
                                    onClick={showModalForm}
                                    id={meal.mealid}
                                  >
                                    Update
                                  </button>

                                  <br />
                                  <br />
                                  <button
                                    className="remove-btn"
                                    onClick={showModal}
                                    id={meal.mealid}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tbody>
                          <tr>
                            <td colSpan="4">
                              <h3 className="text-right">
                                {}
                                {/* Total <ins>&#x20A6;{allTotal}</ins> */}
                              </h3>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="note">
                    {/* <p><span>Note :</span>You can only cancel order cancel in 5 minutes after checkout.</p> */}
                  </div>
                </div>
              )}

              <div
                className="col-lg-4 col-md-4"
                style={{
                  position: "rekative",
                  top: "-50px",
                }}
              >
                {" "}
                <div className="your-order">
                  <form onSubmit={formSubmitHandler}>
                    <div className="your-order">
                      <div className="login-logo"></div>
                      <h4>Add A Meal</h4>
                      <div>
                        <span className="error-msg">
                          {appState.auth.errors.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="video-form"
                          id="name"
                          placeholder="Name"
                          onChange={onChange}
                          required
                          name="name"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="number"
                          className="video-form"
                          id="price"
                          placeholder="Price"
                          onChange={onChange}
                          required
                          name="price"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="video-form"
                          id="url"
                          placeholder="Image URL"
                          onChange={onChange}
                          required
                          name="url"
                          required
                        />
                      </div>
                      <button type="submit" className="login-btn btn-link">
                        Add Meal
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          )}
          {appState.meals.processingToCart ? (
            <ProcessFooter message={"Processing meal action..."} />
          ) : (
            ""
          )}
        </Fragment>
      )}
    </section>
  );
};

export default MealsList;
