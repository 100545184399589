import React from 'react'

const ProcessFooter = ({ message }) => {
    return (
        <div className="cart-footer">
              <img
                src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1585011208/myhotchop/loader_white_zhbatf.gif"
                alt="loader"
                height="20px"
                width="20px"
              />{" "}
              <font style={{ position: "relative", top: "-5px" }}>
               {message}
              </font>
            </div>
    )
}

export default ProcessFooter;