import React, {Fragment, useState} from 'react';
import MessageHeader from '../Layouts/MessageHeader';
import NavBar from '../Layouts/NavBar';
import {useSelector, useDispatch} from 'react-redux';
import {
  signUpAction,
  googleLoginAction,
  facebookLoginAction,
} from '../../actions/userAction';
import Footer from '../Layouts/Footer';
import jwtDecode from 'jwt-decode';
import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const SignUp = ({history}) => {
  const token = localStorage.getItem('token');

  if (token) {
    const role = jwtDecode(token).role;
    if (role === 'admin') {
      window.location.href = '/admin/dashboard';
    } else {
      window.location.href = '/store';
    }
  }

  const appState = useSelector((state) => state);
  const dispatch = useDispatch();
  const myInput = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    password: '',
  };
  const [inputs, setInputs] = useState(myInput);
  const onChange = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    const userInfo = Object.assign({}, inputs);
    dispatch(signUpAction(userInfo, history));
  };

  const responseGoogle = (response) => {
    if (response?.tokenId) {
      dispatch(googleLoginAction(response.tokenId, 'user', history));
    }
  };

  const responseFacebook = (response) => {
    if (response?.accessToken) {
      dispatch(
        facebookLoginAction(
          response.first_name,
          response.last_name,
          response.email,
          history
        )
      );
    }
  };

  return (
    <Fragment>
      <MessageHeader />
      <NavBar />

      <section className="login_register">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="login-container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    {appState.loader.appLoader ? (
                      <div className="login-form">
                        <img
                          src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584489170/myhotchop/loader_zowim3.svg"
                          alt="loading"
                        />
                        <br />
                        Creating your account
                        <br />
                        <br />
                      </div>
                    ) : (
                      <form onSubmit={formSubmitHandler}>
                        <div className="login-form">
                          <div className="login-logo"></div>
                          <div className="create-text">
                            <h4>Create Your Account</h4>
                          </div>
                          <div className="success-msg">
                            {appState.auth.message}
                            {appState.auth.message.trim() !== '' ? (
                              <a
                                href="/login"
                                style={{
                                  color: '#FFA803',
                                }}
                              >
                                {' '}
                                Login here{' '}
                              </a>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            <span className="error">
                              {appState.auth.errors.message}
                            </span>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="video-form"
                              id="emailphonenumber"
                              placeholder="First Name"
                              name="firstname"
                              value={inputs.firstname}
                              onChange={onChange}
                              required
                            />
                          </div>
                          <span className="error">
                            {appState.auth.errors.firstname}
                          </span>
                          <div className="form-group">
                            <input
                              type="text"
                              className="video-form"
                              id="emailphonenumber"
                              placeholder="Last Name"
                              name="lastname"
                              value={inputs.lastname}
                              onChange={onChange}
                              required
                            />
                          </div>
                          <span className="error">
                            {appState.auth.errors.lastname}
                          </span>
                          <div className="form-group">
                            <input
                              type="email"
                              className="video-form"
                              id="emailphonenumber"
                              placeholder="Email"
                              name="email"
                              value={inputs.email}
                              onChange={onChange}
                              required
                            />
                          </div>
                          <span className="error">
                            {appState.auth.errors.email}
                          </span>
                          <div className="form-group">
                            <input
                              type="number"
                              className="video-form"
                              placeholder="Phone number"
                              name="phone"
                              value={inputs.phone}
                              onChange={onChange}
                              minLength="11"
                              required
                            />
                          </div>
                          <span className="error">
                            {appState.auth.errors.phone}
                          </span>
                          <div className="form-group">
                            <input
                              type="password"
                              className="video-form"
                              id="yourPassword"
                              placeholder="Password"
                              name="password"
                              value={inputs.password}
                              onChange={onChange}
                              required
                            />
                          </div>
                          <span className="error">
                            {appState.auth.errors.password}
                          </span>
                          <button type="submit" className="login-btn btn-link">
                            Create Account
                          </button>
                          <br />
                          <br />
                          Or
                          <br />
                          <div className="forgot-password">
                            <GoogleLogin
                              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                              buttonText="Sign-up with Google"
                              onSuccess={responseGoogle}
                              onFailure={responseGoogle}
                              cookiePolicy={'single_host_origin'}
                            />
                            <br />
                            <br />
                            <FacebookLogin
                              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                              isMobile={false}
                              render={(renderProps) => (
                                <div
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                  }}
                                >
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      width: '200px',
                                      padding: '10px',
                                      display: 'inline-block',
                                      backgroundColor: '#4267B2',
                                      color: '#fff',
                                    }}
                                    onClick={renderProps.onClick}
                                  >
                                    Sign-up with Facebook
                                  </span>
                                </div>
                              )}
                              autoLoad={false}
                              fields="email,first_name,last_name"
                              callback={responseFacebook}
                            />
                            <br />
                            <br />
                            <p>
                              Already have an account?{' '}
                              <a href="/login">
                                <span style={{color: '#ffa803'}}>- Login</span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default SignUp;
