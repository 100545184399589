import React, {useEffect, Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  getAllMealsInCart,
  deleteFromCart,
  updateCart,
  placeUserOrder,
  getDeliveryCost,
} from "../../actions/mealsAction";
import ModalConfirm from "../Layouts/ModalConfirm";
import ModalAction from "../Layouts/ModalAction";
import Modal from "../Layouts/Modal";
import {ToastContainer, toast} from "react-toastify";
import ProcessFooter from "../Layouts/ProcessFooter";
import "react-toastify/dist/ReactToastify.css";
import Rave, {VerifyTransaction} from "react-flutterwave-rave";
import jwtDecode from "jwt-decode";

const ViewCart = () => {
  const appState = useSelector((state) => state);
  const token = localStorage.getItem("token");
  const fullName = jwtDecode(token).firstname + " " + jwtDecode(token).lastname;
  const userId = jwtDecode(token).userId;
  const userEmail = jwtDecode(token).email;
  const getLocation = localStorage.getItem("location") || "sapele";

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllMealsInCart());
    dispatch(getDeliveryCost(token));
  }, [dispatch]);
  let allTotal;
  // const deliveryFee = process.env.REACT_APP_DELIVERY_FEE;
  const deliveryFee = appState.meals.deliveryCost;

  if (appState.meals.allMealsInCart.length > 0) {
    allTotal = appState.meals.allMealsInCart
      .map((item) => item.mealtotal)
      .reduce((oldTotal, newTotal) => oldTotal + newTotal);
  }

  const myInput = {
    show: false,
    id: "",
    showQty: "",
    showAction: false,
    cartQty: 1,
    address: "",
    phone: "",
    paymentType: "pod",
  };

  const [inputs, setInputs] = useState(myInput);

  const showActionModal = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      showAction: true,
    }));
  };

  const closeActionModal = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      showAction: false,
    }));
  };

  const showModal = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      show: true,
      id: e.target.id,
    }));
  };

  const showQtyModal = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      showQty: true,
      cartQty: parseInt(e.target.id, 10),
      id: e.target.name.trim(),
    }));
  };

  const closeQtyModal = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      showQty: false,
    }));
  };

  const closeModal = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      show: false,
    }));
  };

  const onPlus = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      cartQty: parseInt(inputs.cartQty, 10) + 1,
    }));
  };

  const onMinus = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      cartQty: parseInt(inputs.cartQty, 10) - 1,
    }));
  };

  const removeFromCart = (e) => {
    e.persist();
    const cartId = e.target.id;

    dispatch(deleteFromCart(cartId, token));
    closeModal(e);
  };

  const updateCartItem = (e) => {
    e.persist();
    dispatch(updateCart(inputs.id, inputs.cartQty, token));
    closeQtyModal(e);
  };

  const close = () => {
    console.log("Payment closed");
  };

  const onChange = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };

  const paymentMethodChange = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      paymentType: e.target.value,
    }));
  };

  let allCarts = [];
  // const callback = (response) => {
  //   return VerifyTransaction({
  //     live: false,
  //     txref: response.tx.txRef,
  //     SECKEY: "FLWSECK_TEST-dc2b6673e70e482962f41f0667201e92-X",
  //   })
  //     .then(function (resp) {
  //       if (resp.status === 200) {
  //         dispatch(
  //           placeUserOrder(
  //             allCarts,
  //             inputs.address,
  //             inputs.paymentType,
  //             inputs.phone,
  //             allTotal,
  //             deliveryFee,
  //             token
  //           )
  //         );
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const callback = (response) => {
    dispatch(
      placeUserOrder(
        allCarts,
        inputs.address,
        inputs.paymentType,
        inputs.phone,
        allTotal,
        deliveryFee,
        token,
        getLocation
      )
    );
  };

  const podPay = () => {
    dispatch(
      placeUserOrder(
        allCarts,
        inputs.address,
        inputs.paymentType,
        inputs.phone,
        allTotal,
        deliveryFee,
        token,
        getLocation
      )
    );
  };

  return (
    <section className="all-partners">
      {appState.loader.appLoader ? (
        <div className="login-form">
          <br />
          <br />
          <br />
          <img
            src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584975303/myhotchop/loader_erskz2.gif"
            alt="loading"
          />
          <br />
          <br />
          <br />
          <font
            style={{
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            Loading cart...
          </font>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : (
        <Fragment>
          {appState.meals.allMealsInCart.length < 1 ? (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <font
                style={{
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                No Meals in cart!
              </font>
              <br />
              <br />
              <a href="/store">
                <font
                  style={{
                    color: "#FFA803",
                  }}
                >
                  Go Home
                </font>
              </a>
              <br />
              <br />
              <br />
            </div>
          ) : (
            <div className="container">
              <ToastContainer />
              <div className="row">
                <div className="col-lg-8 col-md-8">
                  <div className="my-checkout">
                    <ModalConfirm
                      show={inputs.show}
                      closeModal={closeModal}
                      id={inputs.id}
                      remove={removeFromCart}
                    />
                    <Modal
                      show={inputs.showQty}
                      qty={inputs.cartQty}
                      onPlus={onPlus}
                      onMinus={onMinus}
                      showModal={showQtyModal}
                      closeModal={closeQtyModal}
                      btnMessage={"Update"}
                      id={inputs.id}
                      updateCart={updateCartItem}
                    ></Modal>
                    <div className="table-responsive">
                      <table className="table  table-bordered">
                        <thead>
                          <tr>
                            <td className="td-heading">Meal</td>
                            <td className="td-heading">Qty</td>
                            <td className="td-heading">Price (&#x20A6;)</td>
                            <td className="td-heading">Action</td>
                          </tr>
                        </thead>
                        <tbody>
                          {appState.meals.allMealsInCart.map((cart) => {
                            allCarts.push({
                              cartid: `${cart.cartid}`,
                              storename: cart.storename,
                              name: cart.name,
                              qty: cart.qty,
                              price: cart.price,
                              pic: cart.pic1,
                            });
                            return (
                              <tr key={cart.cartid}>
                                <td>
                                  <div className="checkout-thumb">
                                    <a href="?!">
                                      <img
                                        src={cart.pic1}
                                        className="img-responsive"
                                        alt="thumb"
                                        title="thumb"
                                      />
                                    </a>
                                  </div>
                                  <div className="name">
                                    <a href="?!">
                                      <h4>{cart.name}</h4>
                                    </a>
                                    <a href="restaurant_detail.html">
                                      <p>{cart.storename}</p>
                                    </a>
                                  </div>
                                </td>
                                <td className="td-content">{cart.qty}</td>
                                <td className="td-content"> {cart.price}</td>
                                <td>
                                  <button
                                    className="remove-btn"
                                    onClick={showModal}
                                    id={cart.cartid}
                                  >
                                    Remove
                                  </button>
                                  <br />
                                  <br />
                                  <button
                                    className="remove-btn"
                                    onClick={showQtyModal}
                                    id={cart.qty}
                                    name={cart.cartid}
                                  >
                                    &nbsp; Update Quantity
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tbody>
                          <tr>
                            <td colSpan="4">
                              <h3 className="text-right">
                                {}
                                Total <ins>&#x20A6;{allTotal}</ins>
                              </h3>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="note">
                    {/* <p><span>Note :</span>You can only cancel order cancel in 5 minutes after checkout.</p> */}
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-4"
                  style={{
                    position: "rekative",
                    top: "-50px",
                  }}
                >
                  {" "}
                  <div className="your-order">
                    <h4>Your Order</h4>
                    {appState.meals.allMealsInCart.map((mycart) => {
                      return (
                        <div className="order-d" key={mycart.cartid}>
                          <div className="item-dt-left">
                            <span>{mycart.name}</span>
                            <p>
                              {mycart.qty} X &#x20A6;{mycart.price}
                            </p>
                          </div>

                          <div className="item-dt-right">
                            <p>&#x20A6;{mycart.mealtotal}</p>
                          </div>
                        </div>
                      );
                    })}

                    <div className="order-d">
                      <div className="item-dt-left">
                        <span>Delivery Charges</span>
                      </div>
                      <div className="item-dt-right">
                        <p>&#x20A6;{deliveryFee}</p>
                      </div>
                    </div>

                    <div className="total-bill">
                      <div className="total-bill-text">
                        <h5>Total</h5>
                      </div>
                      <div className="total-bill-payment">
                        <p>&#x20A6;{allTotal + deliveryFee}</p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      required
                      name="address"
                      className="video-form"
                      placeholder="Enter delivery address"
                      onChange={onChange}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <div className="form-group">
                      <input
                        type="number"
                        className="video-form"
                        id="phone"
                        placeholder="Phone number"
                        onChange={onChange}
                        required
                        name="phone"
                      />
                    </div>
                  </div>
                  <div className="right-payment-method">
                    <h4>Payment Method</h4>
                    <div className="single-payment-method">
                      <div className="payment-method-name">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="cashon"
                            name="paymentmethod"
                            value="pod"
                            className="custom-control-input"
                            onChange={paymentMethodChange}
                            checked={
                              inputs.paymentType === "pod" ? "checked" : ""
                            }
                          />
                          <label className="custom-control-label" for="cashon">
                            <b style={{fontWeight: "bold"}}>
                              Pay with transfer
                            </b>
                            <br />
                            Account number: 5700001327
                            <br />
                            Bank: Fidelity bank
                            <br />
                            Account name: HOTCHOP DELIVERY
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="single-payment-method">
                      <div className="payment-method-name">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="directbank"
                            name="paymentmethod"
                            value="card"
                            className="custom-control-input"
                            onChange={paymentMethodChange}
                          />
                          <label
                            className="custom-control-label"
                            for="directbank"
                          >
                            <b style={{fontWeight: "bold"}}>
                              Credit/Debit Card
                            </b>
                          </label>
                        </div>
                      </div>
                      {/* <div className="payment-method-details" data-method="bank" style="display: none;">
								<form>
									<div className="form-group">
										<label for="cardNumber">Card Details</label>
										<input type="text" className="video-form" id="cardNumber" name="cardNumber" placeholder="Card Number" />																				
									</div>
									<div className="form-group">
										<input type="text" className="video-form" id="cardHolder" name="cardHolder" placeholder="Holder Name" />																				
									</div>
									<div className="row">
										<div className="col-md-4 col-12 pm-right">
											<div className="form-group">
												<div className="dropdown bootstrap-select"><select className="selectpicker" tabindex="-98">
													<option value="0">Month</option>
													<option value="1">January</option>
													<option value="2">February</option>
													<option value="3">March</option>
													<option value="4">April</option>
													<option value="5">May</option>
													<option value="6">June</option>
													<option value="7">July</option>
													<option value="8">August</option>
													<option value="9">September</option>
													<option value="10">October</option>
													<option value="11">November</option>
													<option value="12">December</option>																																																						  														  
												</select><button type="button" className="btn dropdown-toggle btn-light" data-toggle="dropdown" role="button" title="Month"><div className="filter-option"><div className="filter-option-inner"><div className="filter-option-inner-inner">Month</div></div> </div></button><div className="dropdown-menu " role="combobox"><div className="inner show" role="listbox" aria-expanded="false" tabindex="-1"><ul className="dropdown-menu inner show"></ul></div></div></div>																			
											</div>
										</div>
										<div className="col-md-4 col-12 pm-left pm-right">
											<div className="form-group">
												<div className="dropdown bootstrap-select"><select className="selectpicker" tabindex="-98">
													<option value="0">Year</option>
													<option value="1">2019</option>
													<option value="2">2020</option>
													<option value="3">2021</option>
													<option value="4">2022</option>
													<option value="5">2023</option>
													<option value="6">2024</option>
													<option value="7">2025</option>
													<option value="8">2026</option>
													<option value="9">2027</option>
													<option value="10">2028</option>
													<option value="11">2029</option>
													<option value="12">2030</option>																																																						  														  
												</select><button type="button" className="btn dropdown-toggle btn-light" data-toggle="dropdown" role="button" title="Year"><div className="filter-option"><div className="filter-option-inner"><div className="filter-option-inner-inner">Year</div></div> </div></button><div className="dropdown-menu " role="combobox"><div className="inner show" role="listbox" aria-expanded="false" tabindex="-1"><ul className="dropdown-menu inner show"></ul></div></div></div>																			
											</div>
										</div>
										<div className="col-md-4 col-12 pm-left">
											<div className="form-group">
												<input type="text" className="video-form" id="cardCvv" name="cardCvv" placeholder="Cvv" />																				
											</div>
										</div>
									</div>
									<div className="accepted-check">
										<div className="accpet-checkbox">
											<input type="checkbox" id="c101" name="cb" />
											<label for="c101">Agree to terms and conditions</label>
										</div>
									</div>
								</form>
							</div> */}
                    </div>
                  </div>
                  <ModalAction
                    show={inputs.showAction}
                    close={closeActionModal}
                  />
                  <div
                    className="checkout-btn"
                    style={
                      inputs.address.trim() === "" || inputs.phone.trim() === ""
                        ? {
                            display: "block",
                          }
                        : {
                            display: "none",
                          }
                    }
                  >
                    <button
                      type="submit"
                      className="chkout-btn btn-link"
                      onClick={showActionModal}
                    >
                      Checkout Now
                    </button>
                  </div>
                  <div
                    className="checkout-btn"
                    style={
                      inputs.paymentType === "pod" &&
                      inputs.address.trim() !== "" &&
                      inputs.phone.trim() !== ""
                        ? {
                            display: "block",
                          }
                        : {
                            display: "none",
                          }
                    }
                  >
                    <button
                      type="submit"
                      className="chkout-btn btn-link"
                      onClick={
                        inputs.address.trim() === "" ||
                        inputs.phone.trim() === ""
                          ? showActionModal
                          : podPay
                      }
                    >
                      Checkout Now
                    </button>
                  </div>
                  <div
                    className="checkout-btn"
                    style={
                      inputs.paymentType === "card" &&
                      inputs.address.trim() !== "" &&
                      inputs.phone.trim() !== ""
                        ? {
                            display: "block",
                          }
                        : {
                            display: "none",
                          }
                    }
                  >
                    <Rave
                      pay_button_text="Checkout Now"
                      class={
                        inputs.address.trim() === ""
                          ? "chkout-btn btn-link btn-link-disabled"
                          : "chkout-btn btn-link"
                      }
                      class="chkout-btn btn-link"
                      metadata={[
                        {metaname: "Full Name", metavalue: fullName},
                        {metaname: "userId", metavalue: userId},
                      ]}
                      payment_method="card"
                      customer_email={userEmail}
                      customer_phone={""}
                      amount={allTotal + deliveryFee}
                      ravePubKey={"FLWPUBK-b56b3f0547b38d80b66a7cdedb25dddc-X"}
                      // ravePubKey={
                      //   "FLWPUBK_TEST-62db1e942359beaf26352ea2bb50006d-X"
                      // }
                      callback={callback}
                      onclose={close}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {appState.meals.processingToCart ? (
            <ProcessFooter message={"Processing"} />
          ) : (
            ""
          )}
        </Fragment>
      )}
    </section>
  );
};

export default ViewCart;
