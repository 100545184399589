import React, {Fragment} from "react";
import {Link} from "react-router-dom";

const LocationList = () => {
  const setLocation = (name) => {
    localStorage.setItem("location", name);
  };
  return (
    <section className="explore-recipes" style={{marginBottom: "100px"}}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="new-heading">
              <h1> Select Your Location: </h1>
            </div>
          </div>
        </div>
        <div className="b-recipes">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <Link
                to="/home/11000190"
                onClick={() => {
                  setLocation("abraka");
                }}
              >
                <div className="recipe-item">
                  <img
                    src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584461251/myhotchop/recipe_01_cw7hkn.jpg"
                    alt=""
                  />

                  <div className="overlay">
                    <div className="recipes-title">
                      <h6>Abraka</h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <Link
                to="/home/11009001"
                onClick={() => {
                  setLocation("oghara");
                }}
              >
                <div className="recipe-item">
                  <img
                    src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584461285/myhotchop/recipe_04_waiwjz.jpg"
                    alt=""
                  />
                  <div className="overlay">
                    <div className="recipes-title">
                      <h6>Oghara</h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-6"
              onClick={() => {
                setLocation("sapele");
              }}
            >
              <Link to="/home/11000200">
                <div className="recipe-item">
                  <img
                    src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584461289/myhotchop/recipe_05_ww6zal.jpg"
                    alt=""
                  />
                  <div className="overlay">
                    <div className="recipes-title">
                      <h6>Sapele</h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <a href="/cart">
            <button className="print-btn">My Cart</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default LocationList;
