import React, {Fragment, useState} from 'react';
import MessageHeader from '../Layouts/MessageHeader';
import {useSelector, useDispatch} from 'react-redux';
import NavBar from '../Layouts/NavBar';
import {
  loginAction,
  googleLoginAction,
  facebookLoginAction,
} from '../../actions/userAction';
import Footer from '../Layouts/Footer';
import jwtDecode from 'jwt-decode';
import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const Login = ({history}) => {
  const token = localStorage.getItem('token');

  if (token) {
    const role = jwtDecode(token).role;
    if (role === 'admin') {
      window.location.href = '/admin/dashboard';
    } else {
      window.location.href = '/store';
    }
  }
  const appState = useSelector((state) => state);
  const dispatch = useDispatch();
  const myInput = {
    email: '',
    password: '',
  };

  const [inputs, setInputs] = useState(myInput);

  const onChange = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    const userInfo = Object.assign({}, inputs);
    dispatch(loginAction(userInfo, history, 'user'));
  };

  const responseGoogle = (response) => {
    if (response?.tokenId) {
      dispatch(googleLoginAction(response.tokenId, 'user', history));
    }
  };

  const responseFacebook = (response) => {
    if (response?.accessToken) {
      dispatch(
        facebookLoginAction(
          response.first_name,
          response.last_name,
          response.email,
          history
        )
      );
    }
  };

  return (
    <Fragment>
      <MessageHeader />
      <NavBar />
      <section className="login_register">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="login-container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    {appState.loader.appLoader ? (
                      <div className="login-form">
                        <img
                          src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1584489170/myhotchop/loader_zowim3.svg"
                          alt="loading"
                        />
                        <br />
                        Logging you in
                        <br />
                        <br />
                      </div>
                    ) : (
                      <form>
                        <div className="login-form">
                          <div className="login-logo"></div>
                          <div className="create-text">
                            <h4>Login</h4>
                          </div>
                          <div>
                            <span className="error-msg">
                              {appState.auth.errors.message}
                            </span>
                            <br />
                            <br />
                            <br />
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              className="video-form"
                              id="emailphonenumber"
                              placeholder="Email"
                              onChange={onChange}
                              required
                              name="email"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              className="video-form"
                              id="yourPassword"
                              placeholder="Password"
                              onChange={onChange}
                              required
                              name="password"
                            />
                          </div>
                          <button
                            type="submit"
                            className="login-btn btn-link"
                            onClick={formSubmitHandler}
                          >
                            Login Now
                          </button>
                          <br />
                          <br />
                          <div>Or</div>
                          <div className="forgot-password">
                            <GoogleLogin
                              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                              buttonText="Login with Google"
                              onSuccess={responseGoogle}
                              onFailure={responseGoogle}
                              cookiePolicy={'single_host_origin'}
                            />
                            <br />
                            <br />
                            <FacebookLogin
                              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                              isMobile={false}
                              render={(renderProps) => (
                                <div
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                  }}
                                >
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      width: '175px',
                                      padding: '10px',
                                      display: 'inline-block',
                                      backgroundColor: '#4267B2',
                                      color: '#fff',
                                    }}
                                    onClick={renderProps.onClick}
                                  >
                                    Login with Facebook
                                  </span>
                                </div>
                              )}
                              autoLoad={false}
                              fields="email,first_name,last_name"
                              callback={responseFacebook}
                            />
                            <br />
                            <br />
                            <a href="/forgotpwd">Forgot Password?</a>
                            <p>
                              Don’t have an account?{' '}
                              <a href="/create">
                                <span style={{color: '#ffa803'}}>
                                  - Create An Account
                                </span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Login;
