import React from "react";
import orderContact from "./../../utilities/getLocation";

const OrderReciept = () => {
  return (
    <section className="bill-slip">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="bill-container">
              <div className="new-heading">
                <h1> Thank you so much for you order</h1>
                <p>
                  Your order processing will begin after payment is confirmed.{" "}
                </p>
                <br />
                <div
                  style={{
                    lineSpacing: "4px",
                    color: "#000",
                    lineHeight: "30px",
                    fontSize: "14px",
                  }}
                >
                  To confirm payments and order status, send proof of payment
                  via Whatsapp:&nbsp;
                  {orderContact()}. Thank you.
                </div>
                <br />
              </div>
              <div className="discount-text">
                <img
                  src="https://res.cloudinary.com/dreamqube-technology-limited/image/upload/v1587749809/myhotchop/delivery-icon_agthp5.svg"
                  alt=""
                />
              </div>
              <a href="/my/orders">
                <button className="print-btn">My Orders</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderReciept;
