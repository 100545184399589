import React from 'react';

const CartBar = () => {
	return (
        <section className="title-bar">
		<div className="container">
			<div className="row">
				<div className="col-md-6">
					<div className="left-title-text">
					<h3>Cart</h3>
					</div>
				</div>
                <br /><br /><br /><br />
			</div>
		</div>
	</section>
	);
};

export default CartBar;
