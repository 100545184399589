export const IS_LOADING = 'IS_LOADING';
export const IS_COMPLETE = 'IS_COMPLETE';
export const SERVER_ERROR = 'SERVER_ERROR';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const APP_ERROR = 'APP_ERROR';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const GET_ALL_MEALS_BY_LOCATION = 'GET_ALL_MEALS_BY_LOCATION';
export const GET_SELECTED_LOCATION = 'GET_SELECTED_LOCATION';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const PROCESSING_CART_LOADING = 'PROCESSING_CART_LOADING';
export const PROCESSING_CART_COMPLETE = 'PROCESSING_CART_COMPLETE';
export const GET_ALL_MEALS_IN_CART = 'GET_ALL_MEALS_IN_CART';
export const GET_ITEM_TOTAL_IN_CART = 'GET_ITEM_TOTAL_IN_CART';
export const GET_ALL_STORES = 'GET_ALL_STORES';
export const GET_STORES_MEALS = 'GET_STORES_MEALS';
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const PASSWORD_RESET_SENT = 'PASSWORD_RESET_SENT';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const DELIVERY_COST = 'DELIVERY_COST';
export const START_SEARCH = 'START_SEARCH';
export const SEARCH_COMPLETE = 'SEARCH_COMPLETE';
export const SEARCH_MEALS_LIST = 'SEARCH_MEALS_LIST';


