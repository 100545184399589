import React, { Fragment } from "react";
import Footer from "../Layouts/Footer";
import MessageHeader from '../Layouts/MessageHeader';
import NavBar from '../Layouts/NavBar';
import FaqContent from "../Layouts/FaqContent";

const Faq = () => {
  return (
    <Fragment>
      <MessageHeader />
      <NavBar />
      <FaqContent />
      <Footer />
    </Fragment>
  );
};

export default Faq;
