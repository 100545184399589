import React from "react";

const Modal = ({ closeModal, show, children, onPlus, qty, onMinus, updateCart, btnMessage, id }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  let disabled = false;
  if (qty === 1) {
    disabled = true;
  }
  if(!id){
    id=""
  }
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {children}
        <div
          className="Qty"
          style={{
            textAlign: "center",
            color: "#000",
            position: "relative",
            top: "-10px"
          }}
        >
          <b>Select Quantity</b>
          <br />
          <br />
          <div className="input-group">
            <div className="input-group-prepend">
              <button
                className="minus-btn btn-sm"
                id="minus-btn"
                onClick={onMinus}
                disabled={disabled}
              >
                <i className="fas fa-minus-square" />
              </button>
            </div>
            <input
              type="number"
              id="qty_input"
              className="qty-control"
              value={qty}
              min="1"
              name="qty"
              // ref={textInput}
            />
            <div className="input-group-prepend">
              <button className="add-btn btn-sm" id="plus-btn" onClick={onPlus}>
                <i className="fas fa-plus-square" />
              </button>
            </div>
          </div>
          <button
            type=""
            className="add-cart-modal btn"
            onClick={updateCart}
            id={id}
            // onClick={showModal}
            // id={meal.mealid + "-" + meal.storeid}
          >
            {/* <span id={meal.mealid + "-" + meal.storeid}>
                              Add{" "}
                              <i
                                className="fas fa-plus-square"
                                id={meal.mealid + "-" + meal.storeid}
                              />
                            </span> */}
            <span>
              <i className="fas fa-plus-square" /> {btnMessage}{" "}
            </span>
          </button>{" "}
        </div>
        <button onClick={closeModal} className="close">
          &times;
        </button>
      </section>
    </div>
  );
};

export default Modal;
