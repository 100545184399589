import React from "react";
import { Switch, Route } from "react-router-dom";
// import Login from '../components/Pages/Login';
import "../styles/App.scss";
// import checkAuthentication from '../hoc/Authentication';
import Home from "../components/Pages/Home";
import ViewMeal from "../components/Pages/ViewMeal";
import Login from "../components/Pages/Login";
import AdminLogin from "../components/Pages/LoginAdmin";
import SignUp from "../components/Pages/SignUp";
import checkAuthentication from "../hoc/Authentication";
import Store from "../components/Pages/Store";
import Dashboard from '../components/Pages/Dashboard';
import AddMeals from '../components/Pages/AddMeals';
import Cart from '../components/Pages/Cart';
import OrderSteps from '../components/Pages/OrderSteps';
import Contact from "../components/Pages/Contact";
import OrderConfirm from "../components/Pages/OrderConfirm"
import OrderConfirmTransfer from "../components/Pages/OrderConfirmTransfer"
import MyOrders from "../components/Pages/MyOrders"
import ForgotPassword from "../components/Pages/ForgotPassword"
import ResetConfirm from "../components/Pages/ResetConfirm"
import PageNotExist from "../components/Pages/PageNotExist";
import Faq from "../components/Pages/Faq";
import SearchMeal from '../components/Pages/SearchMeal'

const App = () => (
  <div>
    <Switch>
      <Route
        exact
        path="/home/:locationId"
        render={props => {
          return <ViewMeal {...props}/>;
        }}
      />
      <Route
        exact
        path="/search/:locationId"
        render={props => {
          return <SearchMeal {...props}/>;
        }}
      />
      <Route exact path="/" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgotpwd" component={ForgotPassword} />
      <Route exact path="/reset/:token" component={ResetConfirm} />
      <Route exact path="/admin/login" component={AdminLogin} />
      <Route exact path="/create" component={SignUp} />
      <Route exact path="/store" component={checkAuthentication(Store)} />
      <Route exact path="/admin/dashboard" component={checkAuthentication(Dashboard)} />
      <Route exact path="/admin/addMeals/:name/:sid" component={checkAuthentication(AddMeals)} />
      <Route exact path="/cart" component={checkAuthentication(Cart)} />
      <Route exact path="/order/confirm/card" component={checkAuthentication(OrderConfirm)} />
      <Route exact path="/order/confirm/transfer" component={checkAuthentication(OrderConfirmTransfer)} />
      <Route exact path="/my/orders" component={checkAuthentication(MyOrders)} />
      <Route exact path="/order/steps" component={OrderSteps} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/faq" component={Faq} />


      <Route to="*" component={PageNotExist} />
    </Switch>
  </div>
);

export default App;
